import * as React from 'react';
import Company from "../company/Company";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import {setMobileMenuState} from "../actions";
import CompanyData from "../company/CompanyData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchOrder from "../cart/SearchOrder";

function MobileMenu(params: {company: Company, colors: Colors, dispatch: Function, mobileMenuOpen: boolean}) {
  const {company, dispatch, mobileMenuOpen} = params;
  const image = `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${company.logo}`;

  return (
      <div className="block sm:hidden">
        {mobileMenuOpen && <div className="overlay z-30" onClick={() => dispatch(setMobileMenuState(false))} />}
        <div className="bg-white w-3/4 p-3 fixed left-0 top-0 bottom-0 z-40 transition ease-out duration-300 overflow-y-auto"
             style={{transform: mobileMenuOpen ? 'translateX(0)' : 'translateX(-100%)'}}>
          <div className="flex mb-3 items-center justify-between my-10">
            <div className="flex items-center">
              <img className="w-12 mr-3" src={image} alt={company.name}/>
              <span className="text-black titleShop font-bold">{company.name}</span>
            </div>
            <button onClick={() => dispatch(setMobileMenuState(false))} className="modal-close">
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
          <CompanyData />
          <div className="mt-6">
            <SearchOrder />
          </div>
        </div>

      </div>
  );
}

export default connect(mapStateToProps)(MobileMenu);
