import {Cart} from "./Cart";
import Company from "../company/Company";

export function calculateCartTotal(cart: Cart) {
  return cart.items.reduce((a, b) => {
    const price = b.discount_price || b.price;
    return a + (price * b.quantity || 0);
  }, 0);
}

export function findCompanyCart(company: Company, carts: Cart[]) {
  if (!company || !carts) {
    return new Cart();
  }

  return carts.find(c => c.company === company.uuid) || new Cart();
}
