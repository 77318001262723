import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { Colors, mapStateToProps } from '../reducers';
import { updateProductCount } from '../actions';
// eslint-disable-next-line
import { Dispatch, SetStateAction, useState } from 'react';

type Props = {
  onClickPlus: () => void,
  onClickMinus: () => void,
  setQuantity: Dispatch<SetStateAction<number>>,
  quantity: number,
  dispatch:Function,
  index: number,
  colors: Colors,
  direction: 'horizontal' | 'vertical',
}


function ChangeCartQuantity(props: Props) {
  const { onClickPlus, onClickMinus, quantity, colors, direction, index, setQuantity, dispatch } = props;

  const InputChangecant = (event: React.FormEvent<HTMLInputElement>,index:number ) => {
    let val = event.currentTarget.value;
    let newValue = 0;
    if(val){
      newValue = parseInt(val);
    }
    dispatch(updateProductCount(index, newValue));
    setQuantity(newValue);
  };

  return (
    <div className={`flex flex-grow items-center ${direction === 'horizontal' ? 'flex-row h-12' : 'flex-col-reverse'}`}>
      <button className="w-10 h-10 flex justify-center items-center rounded-full border text-white"
              style={{ backgroundColor: colors.secondary }}
              onClick={() => onClickMinus()}>
        <FontAwesomeIcon icon={faMinus}/>
      </button>
      <div className="px-3 py-2 flex flex-grow justify-center">
          <input id="quantity" className="w-10 px-1 py-2 flex flex-grow justify-center border p-2 rounded text-center"
                 placeholder="1" type="text"  name="quantity" value={quantity}
                 onChange={(event) => InputChangecant(event, index)}/>
      </div>
      <button className="w-10 h-10 flex justify-center items-center rounded-full border text-white"
              style={{ backgroundColor: colors.primary }}
              onClick={() => onClickPlus()}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>

    </div>
  );
}

export default connect(mapStateToProps)(ChangeCartQuantity);
