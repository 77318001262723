import * as React from 'react';
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import CartListItem from "./CartListItem";
import {Link} from "react-router-dom";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Company from "../company/Company";
import CartHeader from "./CartHeader";
import Fab from "../components/Fab";
import {Cart} from "./Cart";
import useAxios from 'axios-hooks'
import {calculateCartTotal, findCompanyCart} from "./cart.utils";
import Error from "../components/Error";
import {useEffect, useState} from "react";
import Price from "../components/Price";
import Adds from "../components/Adds";
import useWindowDimensions from '../components/useWindowDimensions';

const columnWidths = [
    'w-full sm:w-7/12 md:w-5/12',
    'w-2/12 hidden md:flex justify-center',
    'w-9/12 hidden sm:flex sm:w-3/12 md:flex justify-end sm:justify-center text-center',
    'w-3/12 hidden sm:flex sm:w-2/12 md:w-2/12 text-right',
];

function Checkout(props: {carts: Cart[], colors: Colors, company: Company}) {
  const {carts, colors, company} = props;
  const cart = findCompanyCart(company, carts);
  // eslint-disable-next-line
  const [total, setTotal] = useState(0);
  let ads = [];
  for (let i = 0; i < carts.length; i++) {
    if (carts[i].items) {
      if (carts[i].items.length) {
        for (let j = 0; j < carts[i].items.length; j++) {
          ads.push(carts[i].items[j]);
        }
      }
    }
  }
  const width = useWindowDimensions().width;
  const [minValueError, setMinValueError] = useState(false);
  useEffect(() => {
    const t = calculateCartTotal(cart);
    setTotal(t);
    setMinValueError(company.cart_min_value > 0 && t < company.cart_min_value);
    // eslint-disable-next-line
  }, [cart]);
  const [respose_combos] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/combos`,
      headers: {
        Company: company.uuid,
      }
  });
  const showCombos = respose_combos.data?.success && respose_combos.data?.success.length > 0;

  return (
    <div className="flex flex-col w-full h-full">
      {!cart.items.length && <Error message="No tienes productos en el carrito" />}
      <div className="flex items-center px-3 py-2 text-white top-0 left-0 right-0 z-10 sm:mb-8" style={{backgroundColor: colors.primary}}>
        <Link to={`/${company.slug}/productos`}><FontAwesomeIcon icon={faArrowLeft} /> Regresar</Link>
      </div>
      <div className="flex justify-center content-center items-center h-full p-3 pb-16 sm:pb-24">
        <div className="max-w-3xl w-full">
        {showCombos  ? <Adds actionMessage={company.cart_action_message} action_type={company.action_type} color={colors.secondary} adds={respose_combos.data?.success} /> : ''}
          <CartHeader />
          {cart.paymentMethod.value !== 'cash' && cart.paymentMethod.value !== 'credit_card' && <div className="border p-3 bg-green-200 text-center text-black text-lg rounded row" role="alert">
            <span className="inline" style={{fontSize: width > 750 ? '0.95rem': '0.8rem'}}>Se enviará el número de cuenta al finalizar el proceso de compra</span>
            {/*<span className="relative inline float-right px-4">
              <svg className="fill-current h-6 w-6 text-black text-sm" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>*/}
          </div>}
          {cart.paymentMethod.value !== 'cash' && cart.paymentMethod.value !== 'transfer' && <div className="border p-3 bg-green-200 text-center text-black text-lg rounded row" role="alert">
            <span className="inline" style={{fontSize: width > 750 ? '0.95rem': '0.8rem'}}>Se enviará el enlace de pago al finalizar el proceso de compra</span>
            {/*<span className="relative inline float-right px-4">
              <svg className="fill-current h-6 w-6 text-black text-sm" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>*/}
          </div>}
          {minValueError && <p className="border p-3 bg-yellow-400 text-center text-black text-lg rounded">El pedido mínimo es de {<Price price={company.cart_min_value} />}</p>}
          <div className="flex justify-between md:justify-center mb-5 px-6 mt-8">
            <div className={`${columnWidths[0]} hidden sm:flex`}>
              <span className="font-bold">Producto</span>
            </div>
            <div className={columnWidths[1]}>
              <span className="font-bold">Precio</span>
            </div>
            <div className={columnWidths[2]}>
              <span className="font-bold">Cantidad</span>
            </div>
            <div className={columnWidths[3]}>
              <span className="font-bold">Total</span>
            </div>
          </div>
          <div className="shadow border rounded px-3 pt-3 bg-white">
            {cart.items.map((item, index) => <CartListItem key={index} item={item} columnWidths={columnWidths} index={index} />)}
          </div>
        </div>
      </div>
      <Fab>
        <Link className="fab-button uppercase"
              to={minValueError ? '#' : 'datos'}
              style={{backgroundColor: colors.accent, opacity: minValueError ? 0.5 : 1}}>
          Siguiente <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
        </Link>
      </Fab>
    </div>
  );
}

export default connect(mapStateToProps)(Checkout);
