import * as React from 'react';
import {BarLoader} from "react-spinners";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";

function Loader(props: {colors: Colors}) {
  const {colors} = props;

  return (
      <div className="w-full flex h-full justify-center items-center">
        <BarLoader height={4} width={100} color={colors.primary} />
      </div>
  );
}

export default connect(mapStateToProps)(Loader);
