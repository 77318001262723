import * as React from 'react';
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swiper from 'react-id-swiper';
import {mapStateToProps} from "../reducers";
import {connect} from "react-redux";
import {useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import Product from "../products/Product";
//import {faArrowLeft, faArrowRight, faTimes, faStar} from "@fortawesome/free-solid-svg-icons";
//import Company from '../company/Company';
import CartControls from '../products/CartControls';
import useWindowDimensions from './useWindowDimensions';
import bgCeleste from '../assets/bg-celeste.png';
function Adds(props: {adds: Product[], color: string, actionMessage:String, action_type: String}) {
  const {url} = useRouteMatch();
  const {adds,color, actionMessage, action_type} = props;
  const width = useWindowDimensions().width;
  //const height = useWindowDimensions().height;
  //const [showModal, setShowModal] = useState(false);
  const [swiper, setSwiper] = useState(null);
  console.log('Swiper: ', swiper);
  let addRef:any = React.createRef();
  /*const goNext = () => {
    if (swiper !== null) {
      // @ts-ignore
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      // @ts-ignore
      swiper.slidePrev();
    }
  };*/

  const params = {
    getSwiper: setSwiper,
    autoplay: {
      enabled: true,
      delay: 6000
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    speed:1500,
  }

  return (<div className="mt-3" style={{borderRadius: '10px'}}>
      <Swiper {...params}>
        {adds.map((add, index) => (
            <div key={index} ref={addRef} className="swiper-slide md:px-12 pb-10 my-2" style={{backgroundImage: add.combo_image ? `url(${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${add.combo_image})` : add.image ? `url(${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${add.image})` : `url(${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${bgCeleste})`, backgroundRepeat: 'no-repeat',backgroundSize: add.combo_image ? 'contain': '', minHeight: add.combo_image ? '100%' : '250px', maxHeight: add.combo_image ? '100%': '500px', borderRadius: '10px'}}>
              <div className="relative sm:float-left bottom-0" style={{marginTop: width > 750 ? '20%' : '27.5%' }} >
                {action_type === 'buy' ? < CartControls product={add} /> :
                  <Link to={`${url}/${add.uuid}`}>
                    <button className="h-12 text-white text-center py-2 rounded w-full px-3 sm:px-10" style={{backgroundColor: color}}>{actionMessage}</button>
                 </Link>
                }
              </div>

              {/*<img className="min-w-full mb-5" src={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${add.image}`} alt={add.name}/>*/}
            </div>
        ))}
      </Swiper>
      {/*<button className="hidden md:block absolute left-0 ml-3 rounded-full bg-white border z-30 w-10 h-10 flex justify-center items-center"
              style={{top: '50%', transform: 'translateY(-50%)'}}
              onClick={goPrev} ><FontAwesomeIcon icon={faArrowLeft} size="lg" /></button>
      <button className="hidden md:block absolute right-0 mr-3 rounded-full bg-white border z-30 w-10 h-10 flex justify-center items-center"
              style={{top: '50%', transform: 'translateY(-50%)'}}
              onClick={goNext} ><FontAwesomeIcon icon={faArrowRight} size="lg" /></button>*/}
  </div>);
}

export default connect(mapStateToProps)(Adds);
