import {CartItem} from "./CartItem";
import {PaymentMethods} from "../company/PaymentMethods";

export type LocationOption = {
  label: '',
  value: '',
};

export class Cart {
  company!: string;
  items: CartItem[] = [];
  paymentMethod = PaymentMethods[0];
  location!: LocationOption;
}
