import * as React from 'react';
import useAxios from 'axios-hooks'
import Filters from "./Filters";
// eslint-disable-next-line
import {Redirect, Route, Switch, useRouteMatch, useLocation, Link} from "react-router-dom";
import ProductList from "./ProductList";
import Loader from "../components/Loader";
import Error from "../components/Error";
import Company from "../company/Company";
import CompanyData from "../company/CompanyData";
import {ALL_ROUTE} from "../contants";
import MiniCart from "../cart/MiniCart";
import Categories from "./Categories";
import Header from "../components/Header";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import MobileMenu from "../components/MobileMenu";
import SearchOrder from "../cart/SearchOrder";
import Promotions from "../components/Promotions";
import Sections from "./Sections";
import useWindowDimensions from '../components/useWindowDimensions';
function Catalog(props: { company: Company, colors: Colors }) {
  const {company, colors} = props;
  const {path, url} = useRouteMatch();
  const location = useLocation();
  const width = useWindowDimensions().width;
  const [{data, loading, error}] = useAxios(
      {
        url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/product-categories`,
        headers: {
          Company: company.uuid,
        }
      }
  );

  const [response] = useAxios(
      {
        url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/promotions`,
        headers: {
          Company: company.uuid,
        }
      }
  );
  const [response_sections] = useAxios(
      {
        url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/product-sections`,
        headers: {
          Company: company.uuid,
        }
      }
  );

  if (loading || response.loading) return <Loader/>
  if (error) return <Error message={error.message}/>

  const showPromotions = response.data?.success && response.data.success.length > 0;
  const showSections = response_sections.data?.success && response_sections.data.success.length > 0;
  return (
      <div>
        <MobileMenu/>
        <Header promotions={response.data?.success}/>
        {company.action_type === 'buy' ? <MiniCart/>: ''}
        <div className="container mx-auto">
          <div className="flex overflow-x-auto justify-center mb-4">
            <p className="px-3 text-lg sm:mb-6" style={{color: '#727171', fontSize: width > 750 ? '1.15rem':  '1.0625rem', marginTop: width < 350  ? '5%' : 'auto', marginBottom: width < 750 ? '2px' : 'auto'}}>{company.text}</p>
          </div>

          {showSections && <Sections sections={response_sections.data?.success}/>}

          <Categories categories={data.success}/>
          <div className="flex flex-col sm:flex-row justify-center content-center w-full h-full pb-24">
            <div className='sm:w-3/12 sm:p-4'>
              {showPromotions && <Promotions color={colors.secondary}
                                             promotions={response.data?.success}
                                             action_type={company.action_type}
                                             action_message={company.action_message}
                                             contact_fields={company.contact_fields}
                                             ecomerce_form_uuid={company.ecommerce_form.uuid}
                                             company_uuid={company.uuid}
                                             className="hidden text-xs lg:text-base sm:flex rounded-full py-2 pl-4 pr-2 text-white mb-4 w-full justify-between items-center"/>}
              <Filters/>
              <div className="mt-10 hidden sm:block">
                <SearchOrder/>
              </div>
              <CompanyData className="hidden sm:block mt-8"/>
            </div>
            <div className="sm:w-9/12 p-4 pt-2">
              <Switch>
                <Route path={`${path}/:category`}>
                  <ProductList/>
                </Route>
                <Route exact path={path}>
                  <Redirect
                      to={{
                        pathname: `${url}/${ALL_ROUTE}`,
                        state: {from: location}
                      }}
                  />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(Catalog);
