import * as React from 'react';
import Product from "./Product";
import {connect} from "react-redux";
import {
  addToCart,
  removeFromCart,
  updateProductCount
} from "../actions";
import {mapStateToProps} from "../reducers";
import AddToCartButton from "./AddToCartButton";
import ChangeCartQuantity from "./ChangeCartQuantity";
import {Cart} from "../cart/Cart";
import Company from "../company/Company";
import {findCompanyCart} from "../cart/cart.utils";
import {useEffect, useState} from "react";

type Props = {
  product: Product,
  dispatch: Function,
  carts: Cart[],
  company: Company,
  className?: string
}

function CartControls(props: Props) {
  const {product, dispatch, carts, company} = props;
  const cart = findCompanyCart(company, carts);
  const cartIndex = cart.items.findIndex(item => item.uuid === product.uuid);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (cartIndex !== -1) {
      setQuantity(cart.items[cartIndex].quantity);
    }
  }, [cart, cartIndex]);

  const decrementCart = () => {
    const q = quantity - 1;

    // Update product quantity
    if (quantity > 0) {
      dispatch(updateProductCount(cartIndex, q));
    }

    // Remove the product from the cart
    if (quantity === 1) {
      dispatch(removeFromCart(cartIndex));
    }

    setQuantity(q);
  }

  const incrementCart = () => {
    const q = quantity + 1;

    if (cartIndex === -1) {
      // Add the product to the cart
      dispatch(addToCart(product));
    } else {
      // Update product quantity
      dispatch(updateProductCount(cartIndex, q));
    }

    setQuantity(q);
  }

  return (
      <div className={props.className}>
        {quantity > 0 && <ChangeCartQuantity quantity={quantity}
                                             direction="horizontal"
                                             onClickMinus={decrementCart}
                                             onClickPlus={incrementCart}
                                             index={cartIndex}
                                             setQuantity={setQuantity}
        />}
        {quantity === 0 && <AddToCartButton onClick={incrementCart}/>}
      </div>
  );
}

export default connect(mapStateToProps)(CartControls);
