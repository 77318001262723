import * as React from 'react';
// @ts-ignore
import CurrencyFormat from "react-currency-format";
//import useWindowDimensions from '../components/useWindowDimensions';

function Price(props: {price: number, className?: string}) {
  const {price} = props;
  const finalPrice = (Math.round(price * 100) / 100).toFixed(2);
  return (<CurrencyFormat {...props} value={finalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'}/>);
}

export default Price;
