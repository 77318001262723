import React from 'react';
import bgImage from '../assets/bg-index.jpg';
import {Link} from "react-router-dom";

function Home() {
  return (
    <div className="flex justify-center cyzaontent-center items-center w-full h-full">
      <img className="absolute z-0 w-full h-full object-cover" src={bgImage} alt="Fondo"/>
      <div className="absolute z-10 lg:top-0 bottom-0 p-8 md:p-24 right-0 w-full md:w-8/12 lg:w-6/12 md:max-w-5xl flex flex-col text-center text-white text-3xl">
        <Link to="/leadshop" className="rounded-full py-4 w-full mb-8" style={{backgroundColor: '#EAB611'}}>Tiendas LeadShop</Link>
        <a href="https://leadbox.ec/app" className="rounded-full py-4 w-full" style={{backgroundColor: '#06B7AA'}}>Login LeadBox</a>
      </div>
    </div>
  );
}

export default Home;
