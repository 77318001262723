import * as React from 'react';
import useWindowDimensions from './useWindowDimensions';

function Fab(props: {children: any}) {
  const width = useWindowDimensions().width;
  //const height = useWindowDimensions().height;
  return (
    <div className="fixed bottom-0 sm:mb-8 left-0 right-0 flex justify-center" style={{zIndex: width > 750 ? 15 : 11, bottom: width > 750 ? '-20px' : '0px'}}>
      {props.children}
    </div>
  );
}

export default Fab;
