import * as React from 'react';
import {Redirect, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import useAxios from 'axios-hooks';
import Catalog from "../products/Catalog";
import Loader from "../components/Loader";
import Error from "../components/Error";
import {setCompany as setReduxCompany, setCompanyColors} from "../actions";
import {defaultCompanyColors} from "../reducers";
import Cart from "../cart/CartView";
import {connect} from "react-redux";
import {ALL_ROUTE} from "../contants";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import Error404 from "../components/Error404";

function Main(props: {dispatch: Function}) {
  const {dispatch} = props;
  const {slug} = useParams();
  const location = useLocation();
  const {path, url} = useRouteMatch();
  const [{data, loading, error}] = useAxios(
      `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/company/${slug}`
  );

  useEffect(() => {
    if (data && data.success) {
      dispatch(setCompanyColors({...defaultCompanyColors, ...data.success.colors}));
      dispatch(setReduxCompany(data.success));
    }
  }, [data, dispatch]);

  return (
      <div className="flex flex-col w-full h-full">
        {loading && <Loader/>}
        {error && error.response?.status !== 404 && <Error message={error.message}/>}
        {error && error.response?.status === 404 && <Error404/>}
        {data && data.success && (
            <div className="flex flex-col flex-grow bg-gray-200">
              <Helmet>
                <title>{data.success.meta_title || data.success.name}</title>
                {data.success.meta_description && <meta name="description" content={data.success.meta_description} />}
                {data.success.head && data.success.head}
                <link rel="icon" href={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${data.success.favicon}`} />
              </Helmet>
              <Switch>
                <Route path={`${path}/productos`}>
                  <Catalog />
                </Route>
                <Route path={`${path}/carrito`}>
                  <Cart />
                </Route>
                <Route exact path={path}>
                  <Redirect
                      to={{
                        // Replaces the last / so that the redirect wont go with //
                        pathname: `${url.replace(/\/$/g, '')}/productos/${ALL_ROUTE}`,
                        state: { from: location }
                      }}
                  />
                </Route>
              </Switch>
            </div>
        )}
      </div>
  );
}

export default connect()(Main);
