import * as React from 'react';
import Company from "../company/Company";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faShareAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import {setMobileMenuState} from "../actions";
import {useEffect, useState} from "react";
// @ts-ignore
import {InlineShareButtons} from 'sharethis-reactjs';
import Promotions from "./Promotions";
import Product from "../products/Product";
import Swiper from "react-id-swiper";
import {SwiperInstance} from "react-id-swiper/lib/types";
import dayjs from 'dayjs';
import useWindowDimensions from './useWindowDimensions';

function Header(params: { company: Company, colors: Colors, dispatch: Function, promotions: Product[] }) {
  const {company, colors, dispatch, promotions} = params;
  const image = `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${company.logo}`;
  const [showShare, setShowShare] = useState(false);
  const [swiper, setSwiper] = useState(null as SwiperInstance);
  const today = dayjs();
  const day = today.day();
  const width = useWindowDimensions().width;
  //const height = useWindowDimensions().height;
  let schedule;
  if(day === 0){
    schedule = company.days[6];
  }else{
    schedule = company.days[day - 1];
  }
  const start = dayjs(today.format('YYYY-MM-DD') + ' ' + schedule.start, "YYYY-MM-DD HH:mm");
  const end = dayjs(today.format('YYYY-MM-DD') + ' ' + schedule.end, "YYYY-MM-DD HH:mm");
  const isOpen = today.isBefore(end) && today.isAfter(start);

  const sliderConfig = {
    getSwiper: setSwiper,
    effect: 'fade',
    width: width < 750 ? width - 150 : width,
    autoplay: {
      enabled: true,
      delay: 3000,
    },
    speed: 1500,
    loop: true,
    autoHeight: false,
  }
  const sliderConfig2 = {
    getSwiper: setSwiper,
    effect: 'fade',
    autoplay: {
      enabled: true,
      delay: 3000,
    },
    speed: 1500,
    loop: true,
    autoHeight: false,
  }


  useEffect(() => {
    // Update swiper here so that the slides get the correct width
    if (swiper) {
      swiper.update();
    }
  }, [swiper]);

  useEffect(() => {
    if (navigator.share !== undefined) {
      setShowShare(true);
    }
  }, []);

  function openShare() {
    navigator
        .share({
          title: `${company.name} - ${company.meta_title}`,
          text: company.meta_description,
          url: window.location.href,
        })
        .then(() => console.log("Shared!"))
        .catch(err => console.error(err));
  }

  let className = 'rounded-full h-3 w-3 inline-block';
  if (isOpen) {
    className += ' bg-yellow-opened';
  } else {
    className += ' bg-red-600';
  }

  /*function hexToRGBA(hex: any, opacity: any) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l: any) {
      return parseInt(hex.length % 2 ? l + l : l, 16)
    }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
  }*/

  return (
      <div>

        <div className="grid grid-cols-1 gap-0">
          <div
              className="flex justify-center text-center items-center text-white lg:text-sm text-xs flex-grow overflow-hidden "
              style={{backgroundColor: colors.primary}}>
            <button className="block sm:hidden"
                    onClick={() => dispatch(setMobileMenuState(true))} style={{ zIndex: 9, color: 'white', minWidth: '50px', fontSize: '0.9rem'}}>
              <FontAwesomeIcon icon={faBars} size="1x"/>
            </button>
            <Swiper {...sliderConfig}>
              {company.messages.map((message, index) => (
                  <div key={index}
                       className="swiper-slide p-2 text-center sm:w-full"
                       style={{backgroundColor: colors.primary, fontSize: width < 350 ? '0.75rem' : width < 750 ? '0.85rem' : '0.95rem'}}>{message}</div>
              ))}
            </Swiper>
            {promotions.length > 0 && <Promotions color={colors.accent}
                                                promotions={promotions}
                                                action_type={company.action_type}
                                                action_message={company.action_message}
                                                contact_fields={company.contact_fields}
                                                ecomerce_form_uuid={company.ecommerce_form.uuid}
                                                company_uuid={company.uuid}
                                                className="rounded-none flex sm:hidden text-xs h-full py-3 pl-4 pr-2 text-white w-full justify-between items-center flex-shrink-0"/>}
          </div>
        </div>

        {company.public_banners.length > 0 &&
        <div className="grid grid-cols-1 gap-0 static">
          <Swiper {...sliderConfig2} containerClass={`h-40 md:h-52 lg:h-64 xl:h-82`}>
            {company.public_banners.map((banner, index) => (
                <div key={index}
                     className="swiper-slide p-0 text-center w-full "
                     style={{
                       background: `url(${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${banner.image}) no-repeat`,
                       backgroundSize: `cover`
                     }}>
                </div>
            ))}
          </Swiper>
          <div className="absolute top-5 right-0 text-white z-10 p-2 mt-10 md:mt-20 px-2  bg-opacity-50"
               style={{
                 borderBottomLeftRadius: '1.5rem',
                 borderTopLeftRadius: '1.5rem',
                 backgroundColor: isOpen ? 'rgba(133, 121, 23, 0.5)': 'rgba(153, 27, 27, 0.5)'
               }}>
            <p className="inline-block ml-3 mr-3" style={{fontSize: width < 750 ? '0.785rem' : '1.05rem'}}>{isOpen ? 'Abierto ahora' : 'Cerrado'}</p>
            <div className={className}>
            </div>
          </div>
        </div>}

        <div className="container mx-auto flex items-center justify-between content-center px-4" >
          <div className="flex items-center relative">
            <div className="h-full md:h-16 md:h-20 lg:h-32 xl:h-36 m-2 ml-0"
                 style={{borderRadius: `1rem`}}>
              <img src={image} alt={company.name} className="h-full rounded border-8 border-white rounded-2xl" style={{position:'relative', zIndex: company.public_banners.length > 0 ? 9 : 0, top: company.public_banners.length > 0 ? width > 750 ? '-50%' : '-50px' : '', marginLeft: width < 750 ? '-7.5px': 'auto', maxWidth: width < 750 ? '42.5%' : 'auto'}}/>
            </div>
            <div className="ml-0 md:ml-4" style={{marginTop: width > 850 ? '-13.5%' : '-10%'}}>
              <h3 className="uppercase text-black font-bold text-lg lg:text-3xl titleShop" style={{fontSize: width >750 ? '1.30rem' : '1.05rem', fontFamily:'Montserrat', marginLeft: width < 750 ? '-115px': 'auto'}}>{company.name}</h3>
              <p className="text-sm text-left" style={{fontSize: width > 750 ? '1.10rem' : '0.925rem', marginLeft: width < 750 ? '-115px': 'auto'}}>{company.title}</p>
            </div>
          </div>
          {showShare && <button className="lg:text-base text-lg sm:ml-2" style={{fontSize: width > 750 ? '1.115rem' : '1.125rem', marginTop: width > 350 ? '-5%' : '25%', position: width < 350  ?  'absolute' : 'relative', marginLeft: width < 350 ? '45%' : 'auto', marginRight: width < 750 ? '5%' : '0%'}} onClick={() => openShare()}> <FontAwesomeIcon
              icon={faShareAlt} className="font-bold"  /> {width > 750 ? 'Compartir': ''}</button>}
          {!showShare && <div className="flex items-center justify-end">
            <span className="mr-3">Compartir</span>
            <InlineShareButtons
                config={{
                  alignment: 'right',  // alignment of buttons (left, center, right)
                  color: 'social',      // set the color of buttons (social, white)
                  enabled: true,        // show/hide buttons (true, false)
                  font_size: 16,        // font size for the buttons
                  labels: null,        // button labels (cta, counts, null)
                  language: 'es',       // which language to use (see LANGUAGES)
                  networks: [           // which networks to include (see SHARING NETWORKS)
                    'whatsapp',
                    'messenger',
                    'facebook',
                    'twitter',
                    'linkedin',
                  ],
                  padding: 12,          // padding within buttons (INTEGER)
                  radius: 4,            // the corner radius on each button (INTEGER)
                  show_total: false,
                  size: 35,             // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  image: image,  // (defaults to og:image or twitter:image)
                  description: 'custom text',       // (defaults to og:description or twitter:description)
                  title: company.name,            // (defaults to og:title or twitter:title)
                }}
            />
          </div>}

        </div>
      </div>
  );
}

export default connect(mapStateToProps)(Header);
