import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swiper from 'react-id-swiper';
import {Colors, mapStateToProps} from "../reducers";
import {connect} from "react-redux";
import {useState} from "react";
import {ProductBody} from "../products/ProductDetail";
import Product from "../products/Product";
import Modal from 'react-modal';
import {faArrowLeft, faArrowRight, faTimes, faStar} from "@fortawesome/free-solid-svg-icons";
import {FormField} from '../components/FormField';
import useWindowDimensions from './useWindowDimensions';
function Promotions(props: {promotions: Product[], className: string, color: string, colors: Colors, action_type: string, action_message: string, contact_fields: FormField[], ecomerce_form_uuid: String, company_uuid : String}) {
  const {className, promotions, color, colors, action_type, action_message, contact_fields, ecomerce_form_uuid, company_uuid} = props;
  const [showModal, setShowModal] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const width = useWindowDimensions().width;
  const goNext = () => {
    if (swiper !== null) {
      // @ts-ignore
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      // @ts-ignore
      swiper.slidePrev();
    }
  };

  const params = {
    getSwiper: setSwiper,
    autoplay: {
      enabled: true,
      delay: 6000
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    speed: 1500
  }

  return (<div>
    <button className={className}
            onClick={() => setShowModal(true)}
            style={{backgroundColor: color, minWidth: '100px'}}>
      <div style={{width: '100%'}}>
        <span>{width > 750 ? 'Promociones' : 'Promos'}</span>
        <FontAwesomeIcon className="ml-3" icon={faStar} />
      </div>
      <span className="px-2 hidden lg:inline rounded-full bg-white text-black">{promotions.length}</span>
    </button>

    <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{content: {maxWidth: 600}}}
    >
      <button className="modal-close" onClick={() => setShowModal(false)}>
        <FontAwesomeIcon icon={faTimes} size="2x" />
      </button>
      <Swiper {...params}>
        {promotions.map((promotion, index) => (
            <div key={index} className="swiper-slide md:px-12 pb-10">
              <ProductBody product={promotion} colors={colors}  action_type={action_type} action_message={action_message} contact_fields={contact_fields} ecomerce_form_uuid={ecomerce_form_uuid} company_uuid={company_uuid}/>
            </div>
        ))}
      </Swiper>
      <button className="hidden md:block absolute left-0 ml-3 rounded-full bg-white border z-30 w-10 h-10 flex justify-center items-center"
              style={{top: '50%', transform: 'translateY(-50%)'}}
              onClick={goPrev} ><FontAwesomeIcon icon={faArrowLeft} size="lg" /></button>
      <button className="hidden md:block absolute right-0 mr-3 rounded-full bg-white border z-30 w-10 h-10 flex justify-center items-center"
              style={{top: '50%', transform: 'translateY(-50%)'}}
              onClick={goNext} ><FontAwesomeIcon icon={faArrowRight} size="lg" /></button>
    </Modal>
  </div>);
}

export default connect(mapStateToProps)(Promotions);
