import * as React from 'react';
import bgImage from '../assets/bg-index.jpg';
import logo from '../assets/logo.svg';

function Error404() {
  return (
      <div className="flex justify-center items-center h-full">
        <img className="absolute z-0 w-full h-full object-cover" src={bgImage} alt="Fondo"/>
        <div className="relative z-20 text-center p-8 bg-black bg-opacity-75 text-white rounded-lg">
          <img className="mb-3" src={logo} alt="LeadShop" style={{maxWidth: 200}}/>
          <h2 className="text-6xl">404</h2>
          <h3 className="uppercase">Empresa no encontrada</h3>
        </div>
      </div>
  );
}

export default Error404;
