import Product from "../products/Product";
import User from "../cart/User";
import {Colors, Sort} from "../reducers";
import Company from "../company/Company";
import {PaymentMethod} from "../company/PaymentMethods";
import {LocationOption} from "../cart/Cart";

export const ADD_TO_CART = 'ADD_TO_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_USER = 'SET_USER';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_PRODUCT_COUNT = 'UPDATE_PRODUCT_COUNT';
export const UPDATE_PRODUCT_NOTE = 'UPDATE_PRODUCT_NOTE';
export const SET_SORTING_METHOD = 'SET_SORTING_METHOD';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_MOBILE_MENU_STATE = 'SET_MOBILE_MENU_STATE';
export const SET_COMPANY_COLORS = 'SET_COMPANY_COLORS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_CART_LOCATION = 'SET_CART_LOCATION';

export function addProducts(products: Product[]) {
  return {
    type: SET_PRODUCTS,
    payload: products,
  }
}

export function addToCart(product: Product) {
  return {
    type: ADD_TO_CART,
    payload: product,
  }
}

export function emptyCart() {
  return {
    type: EMPTY_CART,
  }
}

export function removeFromCart(index: number) {
  return {
    type: REMOVE_FROM_CART,
    payload: index,
  }
}

export function setPaymentMethod(method: PaymentMethod) {
  return {
    type: SET_PAYMENT_METHOD,
    payload: method,
  }
}

export function setCartLocation(location: LocationOption) {
  return {
    type: SET_CART_LOCATION,
    payload: location,
  }
}

export function updateProductCount(index: number, count: number) {
  return {
    type: UPDATE_PRODUCT_COUNT,
    payload: {
      index,
      count,
    },
  }
}

export function updateProductNote(index: number, note: string) {
  return {
    type: UPDATE_PRODUCT_NOTE,
    payload: {
      index,
      note,
    },
  }
}

export function setUser(user: User) {
  return {
    type: SET_USER,
    payload: user,
  }
}

export function setSortingMethod(sort: Sort) {
  return {
    type: SET_SORTING_METHOD,
    payload: sort,
  }
}

export function setCompany(company: Company) {
  return {
    type: SET_COMPANY,
    payload: company,
  }
}

export function setCompanyColors(colors: Colors) {
  return {
    type: SET_COMPANY_COLORS,
    payload: colors,
  }
}

export function setMobileMenuState(isOpen: boolean) {
  return {
    type: SET_MOBILE_MENU_STATE,
    payload: isOpen,
  }
}
