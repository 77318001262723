export class PaymentMethod {
  label!: string;
  value!: string;
}

export const PaymentMethods: PaymentMethod[] = [
  {label: 'Transferencia', value: 'transfer'},
  {label: 'Efectivo', value: 'cash'},
  {label: 'Tarjeta de Crédito', value: 'credit_card'},
]
