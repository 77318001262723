import update from 'immutability-helper';
import {Action} from "redux";
import {
  SET_PRODUCTS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_PRODUCT_COUNT,
  UPDATE_PRODUCT_NOTE,
  SET_USER,
  SET_SORTING_METHOD,
  SET_COMPANY_COLORS,
  SET_COMPANY,
  SET_PAYMENT_METHOD, SET_MOBILE_MENU_STATE, SET_CART_LOCATION, EMPTY_CART,
} from "../actions";
import Product from "../products/Product";
import User from "../cart/User";
import Company from "../company/Company";
import {Cart} from "../cart/Cart";

export const initialSortingMethods: Sort[] = [
  {label: 'Nombre de A-Z', method: 'name', direction: 'asc', active: true, value: 'name-asc'},
  {label: 'Nombre de Z-A', method: 'name', direction: 'desc', active: false, value: 'name-desc'},
  {label: 'Menor precio', method: 'price', direction: 'asc', active: false, value: 'price-asc'},
  {label: 'Mayor precio', method: 'price', direction: 'desc', active: false, value: 'price-desc'},
];

export const defaultCompanyColors: Colors = {
  primary: '#3C657D',
  secondary: '#284458',
  accent: '#505050',
}

export type Sort = {
  method: string;
  direction: 'asc' | 'desc' | 'random',
  label?: string;
  active?: boolean;
  value: string;
}

export type Colors = {
  primary: string;
  secondary: string;
  accent: string;
}

export type AppState = {
  carts: Cart[],
  products: Product[],
  user: User,
  sorting: Sort,
  colors: Colors,
  company: Company,
  mobileMenuOpen: boolean,
}

export const mapStateToProps = (state: AppState) => ({
  colors: state.colors,
  carts: state.carts,
  products: state.products,
  sorting: state.sorting,
  user: state.user,
  company: state.company,
  mobileMenuOpen: state.mobileMenuOpen,
});

const initialState: AppState = {
  carts: [],
  products: [],
  user: new User(),
  sorting: initialSortingMethods[0],
  colors: defaultCompanyColors,
  company: new Company(),
  mobileMenuOpen: false,
}

class AppAction implements Action {
  type: any;
  payload: any;
}

export function cartApp(state = initialState, action: AppAction) {
  const index = state.carts.findIndex(c => c.company === state.company.uuid);

  switch (action.type) {
    case SET_USER:
      return update(state, {user: {$set: action.payload}});
    case SET_MOBILE_MENU_STATE:
      return update(state, {mobileMenuOpen: {$set: action.payload}});
    case SET_COMPANY:
      return update(state, {company: {$set: action.payload}});
    case SET_SORTING_METHOD:
      return update(state, {sorting: {$set: action.payload}});
    case SET_COMPANY_COLORS:
      return update(state, {colors: {$set: action.payload}});
    case SET_PRODUCTS:
      return update(state, {products: {$set: action.payload}});
    case SET_PAYMENT_METHOD:
      return update(state, {carts: {
        [index]: {paymentMethod: {$set: action.payload}}
      }});
    case SET_CART_LOCATION:
      return update(state, {carts: {
        [index]: {location: {$set: action.payload}}
      }});
    case EMPTY_CART:
      return update(state, {carts: {
        [index]: {$set : new Cart()}
      }});
    case ADD_TO_CART:
      const cartItem = {...action.payload};
      cartItem.quantity = 1;

      if (index !== -1) {
        return update(state, {
          carts: {
            [index]: {items: {$push: [cartItem]}}
          }
        });
      }

      const newCart = new Cart();
      newCart.company = state.company.uuid;
      newCart.items = [cartItem];

      return update(state, {
        carts: {$push: [newCart]}
      });
    case REMOVE_FROM_CART:
      return update(state, {
        carts: {
          [index]: {items: {$splice: [[action.payload, 1]]}}
        }
      });
    case UPDATE_PRODUCT_COUNT:
      return update(state, {
        carts: {
          [index]: {
            items: {
              [action.payload.index]: {quantity: {$set: action.payload.count}}
            }
          }
        }
      });
    case UPDATE_PRODUCT_NOTE:
      return update(state, {
        carts: {
          [index]: {
            items: {
              [action.payload.index]: {note: {$set: action.payload.note}}
            }
          }
        }
      });
    default:
      return state
  }
}
