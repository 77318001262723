import * as React from 'react';
import {Link, useRouteMatch} from "react-router-dom";
import Product from "./Product";
import {connect} from "react-redux";
import {mapStateToProps} from "../reducers";
import ProductPrice from "./ProductPrice";
import CartControls from "./CartControls";
import Discount from "./Discount";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

function ProductItem(props: { product: Product, action_type: string, action_message: string, color: string , contact_fields: any}) {
  const {url} = useRouteMatch();
  const {product, action_type, action_message, color} = props;

  return (
      <div className="flex flex-col border rounded-md relative overflow-hidden bg-white shadow-md hover:shadow-lg">
        {product.discount_price && <Discount product={product} />}
        <div>
          <Link to={`${url}/${product.uuid}`}>
            <img className="min-w-full"
                 src={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${product.image}`}
                 alt={product.name}/>
            <FontAwesomeIcon icon={faEye} className="absolute right-0 rounded-full text-gray-400 px-1 py-1 top-0" size="2x"/>
          </Link>
        </div>
        <div className="px-3 flex-grow">
          <div className="font-bold">{product.name}</div>
        </div>
        <div className="px-3 pb-3">
          <ProductPrice product={product} />
          {action_type === 'buy' ? <CartControls product={product} className="mt-4" /> :
           <Link to={`${url}/${product.uuid}`}>
             <div className="mt-4">
              <button className="h-12 text-white text-center py-2 rounded w-full px-3 sm:px-2" style={{backgroundColor: color}}>{action_message}</button>
             </div>
            </Link>}
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(ProductItem);
