export default () => {
  const options = {
    facebook: "204255576878251", // Facebook page ID
    whatsapp: "+593996027024", // WhatsApp number
    email: "negocios@leadbox.ec", // Email
    sms: "0996027024", // Sms phone number
    call: "0996027024", // Call phone number
    company_logo_url: "//storage.whatshelp.io/widget/a2/a2d4/a2d4b6baea14b0c40ca1e22f0db4baed/logo.jpg", // URL of company logo (png, jpg, gif)
    greeting_message: "Hola, ¿Cómo podemos ayudarte?", // Text of greeting message
    call_to_action: "Contáctanos", // Call to action
    button_color: "#ffcf01", // Color of button
    position: "right", // Position may be 'right' or 'left'
    order: "whatsapp,facebook,sms,call,email", // Order of buttons
    ga: true, // Google Analytics enabled
    branding: false, // Show branding string
    mobile: true, // Mobile version enabled
    desktop: true, // Desktop version enabled
    greeting: false, // Greeting message enabled
    shift_vertical: 0, // Vertical position, px
    shift_horizontal: 0, // Horizontal position, px
    domain: "leadbox.ec", // site domain
    key: "MWQkWGwVT1qpwJ0JtLULSA", // pro-widget key
  };
  const proto = document.location.protocol, host = "whatshelp.io", url = proto + "//static." + host;
  const s = document.createElement('script');

  s.type = 'text/javascript';
  s.async = true;
  s.src = url + '/widget-send-button/js/init.js';
  s.onload = function () {
    //@ts-ignore
    window.WhWidgetSendButton.init(host, proto, options);
  };

  const x = document.getElementsByTagName('script')[0];
  if (x && x.parentNode) {
    x.parentNode.insertBefore(s, x);
  }
}
