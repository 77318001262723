import * as React from 'react';
import Product from "./Product";

export function calculateDiscount(product: Product) {
  return 100 - ((product.discount_price * 100) / product.price);
}

function Discount(props: {product: Product}) {
  const {product} = props;
  const discount = Math.round(calculateDiscount(product));

  return (
      <div className="absolute left-0 top-0 mt-6 bg-red-500 text-white text-sm px-3 rounded-r-full">-{discount}%</div>
  );
}

export default Discount;
