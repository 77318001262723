import * as React from 'react';
import Company from './Company';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faClock, faPhone, faTimes, faUserClock, faMapMarkerAlt, faMapPin} from '@fortawesome/free-solid-svg-icons';

import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

import {Colors, mapStateToProps} from '../reducers';
import {connect} from 'react-redux';
import Input from '../components/Input';
import useAxios from 'axios-hooks';
import {Location} from '../cart/UserData';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
// eslint-disable-next-line
import {NavLink} from 'react-router-dom';
import dayjs from "dayjs";
import useWindowDimensions from '../components/useWindowDimensions';

const defaultLocations: Location[] = [];

function CompanyData(params: { company: Company, colors: Colors, className?: string }) {
  const {company, colors, className} = params;
  const [openWhatsAppModal, setOpenWhatsAppModal] = useState(false);
  const [whatsAppMessage, setWhatsAppMessage] = useState('');
  const whatsAppNumber = company.mobile?.replace(/\D/g, '');
  const [locations, setLocations] = useState(defaultLocations);
  const width = useWindowDimensions().width;
  const today = dayjs();
  const day = today.day();
  let schedule;
  if(day === 0){
    schedule = company.days[6];
  }else{
    schedule = company.days[day - 1];
  }
  const start = dayjs(today.format('YYYY-MM-DD') + ' ' + schedule.start, "YYYY-MM-DD HH:mm");
  const end = dayjs(today.format('YYYY-MM-DD') + ' ' + schedule.end, "YYYY-MM-DD HH:mm");
  const isOpen = today.isBefore(end) && today.isAfter(start);
  //console.log('isOpen', isOpen);

  const [{data}] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/locations`,
    headers: {
      Company: company.uuid,
    },
  });

  useEffect(() => {
    if (data && data.success) {
      setLocations(data.success);
    }
  }, [data]);

  const onInputChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setWhatsAppMessage(event.currentTarget.value);
  };

  return (
      <div className={className}>
        <div className="text-center">
          <h3 className="text-lg text-black text-gray-800 mb-4 mt-5 text-center p-2 truncate rounded-lg bg-gray-400">
            <FontAwesomeIcon icon={faUserClock} className="mr-3" size="1x"/> <span style={{fontSize: width > 750 ? '1.05rem': '0.85rem'}}>Medios de Contacto</span> </h3>
          <div className="inline items-center mb-3">
            <button onClick={() => setOpenWhatsAppModal(true)}><FontAwesomeIcon icon={faWhatsapp}
                                                                                className="mr-3 icon-w" size="lg"/>
            </button>
          </div>
          <div className="inline items-center mb-3">
            <a href={`tel:${company.phone}`}>
              <FontAwesomeIcon icon={faPhone} className="mr-3 icon-p" size="lg"/>
            </a>
          </div>

          <div className="inline items-center mb-3">
            <a className="break-all" href={`mailto:${company.email}`}>
              <FontAwesomeIcon icon={faEnvelope} className="mr-3 icon-e" size="lg"/>
            </a>
          </div>
        </div>
        <h3 className="text-lg text-black text-gray-800 mb-4 mt-5 text-center p-2 truncate rounded-lg bg-gray-400">
          <FontAwesomeIcon icon={faMapPin} className="mr-3" size="1x" /> <span style={{fontSize: width > 750 ? '1.05rem': '0.85rem'}}> Entregas en:</span>
        </h3>
        {locations.length > 0 && locations.map((location: any, index) => (
            <div key={index} className="flex items-center mb-3 jus" style={{fontSize: width > 750 ? '1.05rem': '0.85rem'}}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3"
                               size="lg"/>
              {location.name}
            </div>
        ))}
        <h3 className="text-lg text-black text-gray-800 mb-4 mt-5 text-center p-2 truncate rounded-lg bg-gray-400">
          <FontAwesomeIcon icon={faClock} className="mr-3" size="1x" /> <span style={{fontSize: width > 750 ? '1.05rem': '0.85rem'}}> Horarios de Atención:</span>

        </h3>
        <div className="flex items-center mb-3 jus" style={{fontSize: width > 750 ? '1.05rem': '0.85rem'}}>
          <FontAwesomeIcon icon={faClock} className="mr-3" size="lg"/>
          {isOpen?'Abierto Ahora':'Cerrado'}
        </div>
        {company.days ?
            company.days.map((day: any, index) => (
                <div key={index} className="flex items-center mb-3 jus ml-8" style={{fontSize: width > 750 ? '1.05rem': '0.85rem'}}>
                  {(day.start && day.end) ? day.label + ': '  + day.start + ' - ' + day.end: ''}
                </div>
            )) :
            <div className="flex items-center mb-3">
              <FontAwesomeIcon icon={faClock} className="mr-3" size="lg"/>{company.schedule}
            </div>}

        <Modal
            isOpen={openWhatsAppModal}
            onRequestClose={() => setOpenWhatsAppModal(false)}
            style={{content: {maxWidth: 500}}}
        >
          <div>
            <button onClick={() => setOpenWhatsAppModal(false)} className="modal-close">
              <FontAwesomeIcon icon={faTimes} size="2x"/>
            </button>
            <div className="text-center mb-5">
              <FontAwesomeIcon icon={faWhatsapp} size="10x" className="text-green-400"/>
            </div>
            <p className="mb-3">Envíenos un mensaje por medio de WhatsApp y le contestaremos enseguida</p>
            <Input label="Mensaje">
            <textarea className="border p-2 w-full rounded border-gray-500"
                      placeholder="Ingrese su mensaje"
                      value={whatsAppMessage}
                      onChange={onInputChange}/>
            </Input>
            <div className="text-center">
              <a className="py-3 px-6 mt-5 rounded text-white inline-block uppercase"
                 href={`https://wa.me/${whatsAppNumber}?text=${encodeURI(whatsAppMessage)}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 style={{backgroundColor: colors.primary}}>Enviar</a>
            </div>
          </div>
        </Modal>
      </div>
  );
}

export default connect(mapStateToProps)(CompanyData);
