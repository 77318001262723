import * as React from 'react';
import {NavLink} from "react-router-dom";
//import {ALL_ROUTE} from "../contants";
import Section from "./Section";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import useWindowDimensions from '../components/useWindowDimensions';

function Sections(props: { sections: Section[], colors: Colors}) {
  const {sections} = props;
  //console.log('Colores: ', colors);
  //const baseUrl = window.location.origin;
  //const fullUrl = window.location.href;
  //console.log(sections);
  //const {url} = useRouteMatch();
  //let selectedSection: any = sections[0];
  const width = useWindowDimensions().width;
  //const height = useWindowDimensions().height;

  return (
      <div className="flex overflow-x-auto justify-center mb-2 sm:mb-6 top-0 bg-gray-200 z-10">
        <div className="flex sm:justify-center px-3">
          {/*<NavLink exact to={`${url}/${ALL_ROUTE}`}
                   className="block border text-filters-blue py-2 px-5 border-filters-blue bg-transparent active"
  activeClassName="text-white font-bold border-filters-blue bg-filters-blue" style={{borderRadius: '50px 0px 0px 50px', fontSize: width > 750 ? '1.30rem' : '1.125rem'}}>Todos</NavLink>*/}
          { sections.length > 1 ?  sections.map((section, index) => (
              <NavLink key={index} to={section.slug}
                       className="no-break block border py-2 px-5 border-filters-blue bg-transparent text-filters-blue"
                       activeClassName="text-white font-bold border-filters-blue bg-filters-blue" style={{borderRadius: index === sections.length - 1 ? '0px 50px 50px 0px' : index === 0 ?'50px 0px 0px 50px' : '0px 0px 0px 0px', fontSize: width > 750 ? '1.15rem' : '0.95rem', minWidth: width > 750 ? '180px' : '150px', textAlign: 'center'}}>{section.name}</NavLink>
          )) :
          <NavLink key={0} to={sections[0].slug}
                       className="no-break block border py-2 px-5 border-filters-blue bg-transparent text-filters-blue"
                       activeClassName="text-white font-bold border-filters-blue bg-filters-blue" style={{borderRadius: '50px 50px 50px 50px', fontSize: width > 750 ? '1.15rem' : '0.95rem', minWidth: width > 750 ? '180px' : '150px', textAlign: 'center'}}>{sections[0].name}</NavLink>}
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(Sections);
