import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

function Input(props: {icon?: IconProp, label: string, children?: any}) {
  const {icon, label, children} = props;

  return (
      <div className="flex mb-3 items-end w-full">
        {icon && (
            <div className="w-10 text-center">
              <FontAwesomeIcon className="mr-2 mb-2" icon={icon} size='lg' />
            </div>
        )}
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-500">{label}</label>
          {children}
        </div>
      </div>
  );
}

export default Input;
