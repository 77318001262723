import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes, faHistory,} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import { Colors, mapStateToProps } from '../reducers';
import Company from "../company/Company";
import useAxios from 'axios-hooks'
import Loader from "../components/Loader";
import Error from "../components/Error";
import {useEffect, useState} from "react";
import Modal from 'react-modal';
import orderConfirmed from '../assets/order-confirmed.jpg';
import preparingOrder from '../assets/preparing-order.jpg';
import packingOrder from '../assets/packing-order.jpg';
import sendingOrder from '../assets/sending-order.jpg';
import orderGif from '../assets/order.gif';
import useWindowDimensions from '../components/useWindowDimensions';
class Order {
  uuid!: String;
  status!: {name: string, color: string}
}
function SearchOrder(props: { company: Company, colors: Colors, }) {
  // eslint-disable-next-line
  const { company, colors } = props;
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [order, setOrder] = useState(new Order());
  const width = useWindowDimensions().width;
  const [response, http] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/orders`,
    headers: {
      Company: company.uuid,
    }
  }, { manual: true });

  useEffect(() => {
    if (response.data) {
      setOrder(response.data.success);
    }
  }, [response.data]);

  function queryOrder() {
    setOrder(new Order());
    setOpenOrderModal(true);
    http({url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/orders/${orderNumber}`});
  }

  return (
      <div>
        <h3
          className="text-lg text-black text-gray-800 mb-4 mt-5  text-center p-2 truncate rounded-lg bg-gray-400"><FontAwesomeIcon icon={faHistory} className="mr-3" size="1x"/> <span style={{fontSize: width > 750 ? '1.05rem' : '0.85rem'}}>Estado del pedido</span></h3>
        <div className="flex border rounded-lg items-stretch overflow-hidden">
          <input type="text"
                 className="bg-white block w-full py-2 outline-none px-4"
                 placeholder="N° de pedido"
                 value={orderNumber}
                 onChange={(event) => setOrderNumber(event.target.value)}
                 style={{fontSize: width > 750 ? '1.05rem' : '0.85rem'}}/>
          <button className="text-white bg-gray-500 block py-2 px-4"
                  onClick={() => queryOrder()}
                  disabled={!orderNumber}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>

        <Modal
            isOpen={openOrderModal}
            onRequestClose={() => setOpenOrderModal(false)}
            style={{content: {maxWidth: 500}}}
        >
          <div>
            <button onClick={() => setOpenOrderModal(false)} className="modal-close">
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
            {response.loading && <Loader />}
            {response.error?.response?.status === 404 && (
                <div className="mt-10">
                  <Error message={`El pedido con número ${orderNumber} no existe`} />
                </div>
            )}
            {response.error?.response?.status !== 404 && response.error && (
                <div className="mt-10">
                  <Error message={response.error.message} />
                </div>
            )}
            {order.uuid && (
                <div className="mt-5 text-center">
                  <h4>Tu pedido de número <strong className="font-bold">{orderNumber}</strong>:</h4>
                  <p>Está en estado: <strong className="font-bold">{order.status.name}</strong></p>
                  <div className="row">
                    <img src={order.status.name === 'Nuevo' ? orderConfirmed : order.status.name === 'preparando'? preparingOrder : order.status.name === 'empacando'? packingOrder: order.status.name === 'empacando'? sendingOrder: orderGif} alt="estado de pedido" className="px-1 py-4" style={{ maxWidth: '250px' }}/>
                  </div>
                </div>
            )}
          </div>
        </Modal>
      </div>
  );
}

export default connect(mapStateToProps)(SearchOrder);
