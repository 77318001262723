import * as React from 'react';
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import Company from "../company/Company";

function AddToCartButton(params: { onClick: () => void, colors: Colors, company: Company }) {
  const {onClick, colors, company} = params;

  return (
      <button className="h-12 text-white rounded w-full px-3 sm:px-10"
              style={{backgroundColor: colors.secondary}}
              onClick={() => onClick()}>{company.cart_action_message || 'Añadir al carrito'}</button>
  );
}

export default connect(mapStateToProps)(AddToCartButton);
