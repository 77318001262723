import * as React from 'react';
import {connect} from "react-redux";
import {mapStateToProps} from "../reducers";
import {Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import Checkout from "./Checkout";
import UserData from "./UserData";

function CartView() {
  const { path, url } = useRouteMatch();
  let location = useLocation();

  return (
    <div className="w-full h-full">
      <Switch>
        <Route path={`${path}/orden`}>
          <Checkout />
        </Route>
        <Route path={`${path}/datos`}>
          <UserData />
        </Route>
        <Route exact path={path}>
          <Redirect
              to={{
                pathname: `${url}/orden`,
                state: { from: location }
              }}
          />
        </Route>
      </Switch>
    </div>
  );
}

export default connect(mapStateToProps)(CartView);
