import * as React from 'react';
import {connect} from "react-redux";
import {Colors, initialSortingMethods, mapStateToProps, Sort} from "../reducers";
import {setSortingMethod} from "../actions";
import {useEffect, useState} from "react";
import Company from "../company/Company";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

function Filters(props: { sorting: Sort, dispatch: Function, colors: Colors, company: Company }) {
  const {sorting, dispatch, company} = props;
  const [sortingMethods, setSortingMethods] = useState(initialSortingMethods);

  useEffect(() => {
    // Add the Random filter if the company has it enabled
    if (company.show_random_products) {
      sortingMethods[0].active = false;
      sortingMethods.unshift({label: 'Al azar', method: 'name', direction: 'random', active: true, value: 'random'},);
    }

    setSortingMethods(sortingMethods);
    dispatch(setSortingMethod(sortingMethods[0]));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sorting) {

      const index = sortingMethods.findIndex(x => x.label === sorting.label);

      if (index !== -1) {
        let methods = sortingMethods.map((method, i) => {
          method.active = i === index;
          return method;
        });

        setSortingMethods(methods);
      }
    }
    // eslint-disable-next-line
  }, [sorting]);

  function sort(method: Sort, index: number) {
    const methods = sortingMethods.map((method, i) => {
      method.active = i === index;
      return method;
    });
    setSortingMethods(methods);
    dispatch(setSortingMethod(method));
  }

  return (
    <div>
      <div className="hidden sm:block">
        <h3 className="text-lg text-black mb-4 border rounded-lg text-center p-2 text-gray-800 bg-gray-400"> <FontAwesomeIcon icon={faFilter} className="mr-3" size="1x"/> Ordenar por</h3>
        {sortingMethods.map((method, index) => (
            <button key={index}
                    className={`m-1 sm:block w-full text-left border sm:border-0 rounded-full py-1 px-3 ${method.active ? 'font-bold border-black' : ''}`}
                    onClick={() => sort(method, index)}>{method.label}</button>
        ))}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Filters);
