import * as React from 'react';
import ReactPlayer from "react-player";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import backCeles from '../assets/bg-celeste.png';
import logo from '../assets/logo.svg';
import imgHeader from '../assets/img-leadshop.jpg';
import imgOpen from '../assets/img-open.png';
import imgPhone from '../assets/img-phone.png';
import logoN1 from '../assets/logo-n1.png';
import logoN2 from '../assets/logo-n2.png';
import logoN3 from '../assets/log-acero.png';
import logoN4 from '../assets/logo-beam.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import {faMinusCircle,
  faPlusCircle,faTimes} from '@fortawesome/free-solid-svg-icons';
import Input from "../components/Input";
import useAxios from "axios-hooks";
import Error from "../components/Error";
import {renderToString} from "react-dom/server";
import {useEffect, useState} from "react";
import initWhatsHelp from '../services/whats-help';
import Modal from 'react-modal';

const yellow = '#F9CB08';
const lightBlue = '#1CD4C6';
const buttonText = '#042D4F';

const stories = [
  {
    name: 'Comercial Jhonny',
    type: 'Distribuidora de víveres / Quito',
    image: logoN1,
    url: 'https://comprodesde.casa/c-jhonny/productos/todos',
  },
  {
    name: 'Comercial Maza Valladolid',
    type: 'Distribuidora de víveres / Quito',
    image: logoN2,
    url: 'https://comprodesde.casa/mv/productos/todos',
  },
  {
    name: 'Tienda Demo',
    type: 'Restaurantes / Quito',
    image: logoN4,
    url: 'https://comprodesde.casa/bean/productos/todos',
  },
  {
    name: 'Acerogar',
    type: 'Industria metálica / Quito',
    image: logoN3,
    url: 'https://comprodesde.casa/acerogar/productos/todos',
  },
];

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};


const defaultFormData = {
  nombre_del_negocio: '',
  email: '',
  nombre_del_dueno: '',
  numero_de_contacto: '',
  usuarios: 1,
};


const priceMultiplier = [
  0,
  288,
  576,
  864,
  1008,
  1260,
  1512,
  1764,
  2016,
  2268,
  2520,
  2772,
  3024,
  3276,
  3528,
  3240,
  3456,
  3672,
  3888,
  4104,
  4320,
  4536,
  4752,
  4968,
  5184,
  5400,
  5616,
  5832,
  6048,
  6264,
  6480,
  6696,
  6912,
  7128,
  7344,
  7560,
  7776,
  7992,
  8208,
  8424,
  8640,
  8856,
  9072,
  9288,
  9504,
  9720,
  9936,
  10152,
  10368,
  10584,
  10800,
];

function Tooltip() {
  return (
      <div>
        <div>Inversión incluye:</div>
        <ul className="list-disc pl-4">
          <li>Hosting</li>
          <li>URL independiente para tu negocio</li>
          <li>Tienda On-line hasta 50 productos</li>
          <li>Sales Manager de LeadBox</li>
        </ul>
      </div>
  );
}

function LeadShop() {
  const [formData, setFormData] = useState(defaultFormData);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [price, setPrice] = useState(calculatePrice());

  const onInputChange = (event: React.FormEvent<HTMLInputElement>, property: string) => {
    const newUser = {...formData};
    // @ts-ignore
    newUser[property] = event.target.value;
    setFormData(newUser);
  }

  const [{error, loading, data}, http] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/leads`,
    method: 'POST',
    headers: {
      Company: '97f65527-15c3-4fac-a2a5-6f6c835a9bbf',
    }
  }, {manual: true});

  useEffect(() => {
    if (data && data.success) {
      setFormData(defaultFormData);
      setShowSuccessModal(true);
    }
  }, [data]);

  useEffect(() => {
    initWhatsHelp();
  }, []);

  const errorMessage = error?.response?.data.error || error?.message;

  function calculatePrice() {
    const price = 365 + priceMultiplier[formData.usuarios - 1];
    return (Math.round(price * 100) / 100).toFixed(2);
  }

  function sendForm(event: any) {
    event.preventDefault();

    const data = {
      form: 'dfc81095-5265-442c-8893-6b0b48adafd5',
      fields: formData,
    }

    http({data});
  }

  function addUserCount() {
    formData.usuarios = formData.usuarios + 1;

    if (formData.usuarios >= 50) {
      formData.usuarios = 50;
    }

    setFormData({...formData});
    setPrice(calculatePrice());
  }

  function subUserCount() {
    formData.usuarios = formData.usuarios - 1;

    if (formData.usuarios <= 1) {
      formData.usuarios = 1;
    }

    setFormData({...formData});

    setPrice(calculatePrice);
  }

  return <div>
    <header className="flex sm:flex-col md:flex-row justify-between p-3 items-center" style={{background: '#013b49'}}>
      <img className="h-6 md:h-8-mb-1" src={logo} alt="LeadShop"/>
      <div className="flex flex-wrap justify-center" style={{color: yellow}}>
        <a className="hidden sm:flex items-center px-3 py-2" href="#precio">Precio</a>
        <a className="hidden sm:flex items-center px-3 py-2" href="#servicio">Servicio</a>
        <a className="hidden sm:flex items-center px-3 py-2" href="#caracteristicas">Características</a>
        <a className="hidden sm:flex items-center px-3 py-2" href="#uso">Uso</a>
        <a className="hidden sm:flex items-center px-3 py-2" href="#casos-de-exito">Casos de éxito</a>
      </div>
    </header>

    <section className="flex justify-center bg-cover px-0 md:px-6 py-16 md:py-64 relative bg-opacity-75"
             style={{backgroundImage: `url(${imgHeader})`}}>
      <div className="absolute block lg:hidden bg-black opacity-25 top-0 left-0 bottom-0 right-0 z-0"/>
      <div className="container flex justify-center lg:justify-end text-center lg:text-right max-w-5xl relative z-10">
        <div className="lg:w-7/12 flex items-center lg:items-end flex-col mt-10 md:mt-20">
          <h1 className="font-bold text-4xl mt-20 md:mt-0  -mt-0 md:-mt-3 mb-0 md:mb-4"
              style={{color: yellow}}>Tu tienda On-line</h1>
          <p className="text-white text-lg md:text-3xl w-8/12 mb-6 md:-mt-3 leading-0 md:leading-8">contectada en tiempo real con tu fuerza de ventas</p>
          <a href="#precio" className="py-3 px-10 rounded-lg shadow-md font-bold inline-block"
             style={{background: lightBlue, color: buttonText}}>Quiero mi Tienda On-line</a>
        </div>
      </div>
    </section>

    <section className="flex justify-center text-center md:text-left px-6 py-10 md:py-0" style={{background: yellow}}>
      <div className="container flex items-center max-w-5xl">
        <div className="md:w-8/12 md:pr-6">
          <h2 className="text-4xl font-bold mb-5 md:mb-4 leading-10" style={{color: buttonText}}>¿Botones de pago? <br/> ¿E-commerce?</h2>
          <h2 className="text-1xl font-bold mb-5 md:mb-0" style={{color: buttonText}}>¡Suena muy complicado...!</h2>
          <p className="text-1xl" style={{color: '#012F3F'}}>Con LeadShop, entra al mundo digital, con tus productos, de
            una forma <strong className="text-1xl">fácil, rápida y sin complicaciones</strong>.</p>
          <p className="text-1xltext-black" style={{color: '#012F3F'}}>¡Arma tu tienda On-line en minutos y empieza a
            vender de la forma que tu lo sabes hacer!</p>
          <a href="#precio" className="py-3 px-10 rounded-lg shadow-md font-bold inline-block mt-6"
             style={{background: lightBlue, color: buttonText}}>Quiero mi Tienda On-line</a>
        </div>
        <img className="hidden md:block w-4/12 -mt-16 z-10" src={imgOpen} alt="Abierto"/>
      </div>
    </section>

    <section id="servicio" className="flex justify-center py-10 md:py-16 px-6">
      <div className="container flex flex-col md:flex-row items-center max-w-5xl">
        <div className="md:w-6/12 pr-6 mb-5 md:mb-0 text-center md:text-left">
          <h2 className="text-4xl font-bold leading-10 mb-5 md:mb-4" style={{color: buttonText}}>¿Cómo funciona
            LeadShop?</h2>
          <p className="text-1xl mb-4" style={{color: '#012F3F'}}>¡Usar LeadShop es muy fácil!</p>
          <ol className="text-left list-disc pl-5 text-1xl" style={{color: '#012F3F'}}>
            <li>Sube tus productos a tu tienda On-line.</li>
            <li>Comparte el link en tus grupos de Whatsapp y redes sociales.</li>
            <li>Comienza a recibir tus pedidos.</li>
            <li>Atiende los pedidos desde la <strong>WEB</strong> o desde la <strong>APP.</strong></li>
            <li>Acuerda con tu cliente la forma de pago y dirección de envío.</li>
          </ol>
          <p className="text-1xl mb-4 mt-4" style={{color: '#012F3F'}}>¡Todo sin complicaciones!</p>
        </div>

        <div className="md:w-6/12 player-wrapper">
          <ReactPlayer
              url="https://www.youtube.com/watch?v=YC0o1HY7hOA&feature=youtu.be"
              width='100%'
          />
        </div>
      </div>
    </section>

    <section id="precio" className="flex justify-center py-16 bg-gray-400">
      <div className="flex flex-col lg:flex-row items-center container max-w-5xl">
        <div className="lg:w-7/12 p-2 h-full">
          <div className="flex flex-col rounded-lg bg-white p-6 h-full">
            <p className="text-4xl font-bold text-center leading-10" style={{color: '#012F3F'}}>Tienda
              On-line <br/><span style={{color: yellow}}>+</span> Sales Manager</p>
            <p className="text-center text-1xl mt-4" style={{color: buttonText}}>Estás a un paso de tener tu tienda
              On-line</p>
            <div className="flex flex-col mt-5 items-center justify-center flex-grow">
              <div className="pb-6 text-right">
                <FontAwesomeIcon data-tip={renderToString(<Tooltip/>)}
                                 data-html={true}
                                 icon={faQuestionCircle}
                                 color={lightBlue} size="2x"/>
                <div className="rounded-lg text-white px-6 font-bold text-center" style={{background: lightBlue}}>
                  <span className="text-3xl">$</span>
                  <span className="text-6xl">{price}</span>
                </div>
                <div className="text-center">+IVA (Inversión por 1 año)</div>
              </div>
              <div className="text-center no-select">
                <div className="flex items-center font-bold">
                  <FontAwesomeIcon className="cursor-pointer" icon={faMinusCircle} color={yellow} size="4x"
                                   onClick={() => subUserCount()}/>
                  <span className="text-6xl flex-grow text-center"
                        style={{color: buttonText}}>{formData.usuarios}</span>
                  <FontAwesomeIcon className="cursor-pointer" icon={faPlusCircle} color={yellow} size="4x"
                                   onClick={() => addUserCount()}/>
                </div>
                <p>Ingresa número de vendedores</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-5/12 p-2 h-full">
          <form className="flex flex-col rounded-lg bg-white p-10" onSubmit={sendForm}>
            <h4 className="text-3xl mb-4 flex flex-grow justify-center" style={{color: buttonText}}>Ingresa tus datos</h4>
            <Input label="">
              <input type="text"
                     required={true}
                     placeholder="Nombre del negocio"
                     value={formData.nombre_del_negocio}
                     className="border-b border-gray-700 mb-4 bg-gray-100 p-2"
                     onChange={(event) => onInputChange(event, 'nombre_del_negocio')}
              />
            </Input>
            <Input label="">
              <input type="text"
                     placeholder="Nombre/Apellido"
                     value={formData.nombre_del_dueno}
                     required={true}
                     className="border-b border-gray-700 mb-4 bg-gray-100 p-2"
                     onChange={(event) => onInputChange(event, 'nombre_del_dueno')}
              />
            </Input>
            <Input label="">
              <input type="text"
                     required={true}
                     placeholder="Telf. Móvil"
                     value={formData.numero_de_contacto}
                     className="border-b border-gray-700 bg-gray-100 p-2"
                     onChange={(event) => onInputChange(event, 'numero_de_contacto')}
              />
            </Input>
            <Input label="">
              <input type="email"
                     value={formData.email}
                     placeholder="Correo electrónico"
                     className="border-b border-gray-700 mb-4 bg-gray-100 p-2"
                     onChange={(event) => onInputChange(event, 'email')}
              />
            </Input>

            <div className="p-2 mt-2 text-center text-gray-500 uppercase">{formData.usuarios} {formData.usuarios === 1 ? 'usuario' : 'usuarios'}</div>

            {errorMessage && <Error message={errorMessage}/>}
            <button className="py-3 w-full px-10 rounded-lg shadow-md font-bold block mt-6"
                    disabled={loading}
                    style={{
                      background: yellow,
                      color: buttonText
                    }}>{loading ? 'Enviando...' : 'Quiero mi Tienda On-line'}</button>
          </form>
        </div>
      </div>
    </section>

    <section id="caracteristicas" className="flex justify-center items-end px-6" style={{background: '#013b49'}}>
      <div className="w-6/12 mr-12 hidden lg:block">
        <img src={imgPhone} alt="Video"/>
      </div>

      <div className="flex items-center pt-5 lg:my-6 lg:mb-32">
        <div className="lg:w-9/12">
          <h2 className="text-4xl font-bold mb-5 text-center lg:text-left leading-10 md:leading-8 mb-5 md:mb-4"
              style={{color: yellow}}>Tu tienda On-line conectada con el Sales Manager de LeadBox®</h2>
          <ul className="text-white list-disc text-1xl pl-6 mb-10 md:mb-0">
            <li>Fácil administración de productos.</li>
            <li>Interfaz práctica para el manejo de los pedidos en tiempo real.</li>
            <li>Ten el control de los pedidos que realizan tus clientes y la gestión de tu fuerza de ventas.</li>
            <li>Estadísticas de gestión de ventas, desempeño de vendedores, productos o servicios más vendidos, etc.
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section id="uso" className="px-6 py-16 flex justify-center text-center md:text-left">
      <div className="container max-w-5xl" style={{color: buttonText}}>
        <h1 className="font-bold text-4xl mb-5 md:mb-3 leading-10 mb-5 md:mb-4">¿A qué tipo de negocios ayuda LeadShop a
          potenciar sus ventas?</h1>
        <p className="text-1xl mb-6 -mt-3">LeadShop es una herramienta muy útil para cualquier tipo de empresa que venda
          productos físicos o servicios.</p>
        <p className="mt-4 text-1xl" style={{color: lightBlue}}>Tiendas / Ferreterías / Panaderías / Restaurantes /
          Florerías / Restaurantes / Boutiques / Abastos / Farmacias / Repuestos / Fruterías / Embutidos / Bicicletas /
          Juguetes / Papelerías / Muebles</p>
      </div>
    </section>

    <section className="flex justify-center bg-cover py-20" style={{backgroundImage: `url(${backCeles})`}}>
      <div className="flex flex-col lg:flex-row items-center container max-w-6xl">
        <div className="md:w-7/12 flex items-center flex-col my-3 ">
          <p className="text-center text-white text-2xl text-sm">Forma parte de la red de pedidos On-line más grande del
            país.</p>
        </div>
        <div className="md:w-4/12 flex items-center flex-col">
          <a href="#precio" className="py-3 px-10 rounded-lg shadow-md font-bold inline-block"
             style={{background: yellow, color: buttonText}}>Quiero mi Tienda On-line</a>
        </div>
      </div>
    </section>

    <section id="casos-de-exito" className="flex justify-center bg-cover py-20 px-6">
      <div className="container max-w-5xl">
        <h1 className="font-bold text-4xl text-center mb-8 lg:mb-4 leading-10" style={{color: buttonText}}>Algunos
          negocios que ya empezaron a vender On-line</h1>
        <Carousel responsive={responsive}>
          {stories.map((story, index) => (
              <div key={index} className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8 lg:m-4 ">
                <div className="text-center md:text-center">
                  <div className="h-64 rounded-lg mb-3 bg-auto bg-no-repeat bg-center"
                       style={{backgroundImage: `url(${story.image})`}}/>
                  <h4 className="text-black text-2x">{story.name}</h4>
                  <h5 className="text-gray-800">{story.type}</h5>
                  <a target='_blank' href={story.url} rel="noopener noreferrer"
                     className="py-3 px-10 rounded-lg shadow-md font-bold inline-block mt-6 "
                     style={{background: yellow, color: buttonText}}>Ver tienda</a>
                </div>
              </div>
          ))}
        </Carousel>
      </div>
    </section>
    <footer className="h-32 flex justify-center items-center" style={{background: '#013b49', color: yellow}}>
      <div className="flex flex-col lg:flex-row container max-w-6xl justify-center ">
        <div className="md:w-3/12 flex items-center flex-col my-3 ">
          <p className="pt-3"> LeadShop es un producto de <a href="http://leadbox.ec">LeadBox®</a></p>
        </div>
        <div className="md:w-2/12 w-12/12 flex items-left flex-col justify-center">
          <a href='https://play.google.com/store/apps/details?id=com.leadbox.app&hl=es&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="md:pr-0 md:pl-0 pr-20 pl-20" alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png'/></a>
        </div>
      </div>
    </footer>
    <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        style={{content: {maxWidth: 500}}}
    >
      <div>
        <button onClick={() => setShowSuccessModal(false)} className="modal-close">
          <FontAwesomeIcon icon={faTimes} size="2x"/>
        </button>
        <div className="mb-5">
          <FontAwesomeIcon icon={faCheckCircle} size="8x"/>
        </div>
        <p>Sus datos fueron enviados con éxito. Un asesor se contactará en breve</p>
      </div>
    </Modal>
  </div>;
}

export default LeadShop;
