import * as React from 'react';
import {useParams, useRouteMatch} from "react-router-dom";
import Loader from "../components/Loader";
import Error from "../components/Error";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDownload, faTimes, faFile} from '@fortawesome/free-solid-svg-icons';
import Product from "./Product";
import useAxios from "axios-hooks";
import User from "../cart/User";
import ProductPrice from "./ProductPrice";
import CartControls from "./CartControls";
import Discount from "./Discount";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import Company from "../company/Company";
import {useEffect, useState} from "react";
import Modal from 'react-modal';
import useWindowDimensions from '../components/useWindowDimensions';
export function ProductBody(props: {product: Product, colors: Colors, action_type: string, action_message: string, contact_fields: any, ecomerce_form_uuid: String, company_uuid: String}) {
  const {product, colors, action_type, action_message, contact_fields, ecomerce_form_uuid,company_uuid} = props;
  console.log('Contact Fields: ', contact_fields);
  let fields =[];
  if (contact_fields.length) {
    for (let i = 0; i < contact_fields.length; i++) {
      fields.push(contact_fields[i]);
    }
  }
  const width = useWindowDimensions().width;
  let detailRef : any = React.createRef();
  let formRef : any = React.createRef();
  const [user, setUser] = useState(new User());
  const [response, http] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/leads`,
    method: 'POST',
    headers: {
      Company: company_uuid,
    }
  }, {manual: true});
  const onInputChange = (event: React.FormEvent<HTMLInputElement>, property: string) => {
    const newUser = {...user};
    // @ts-ignore
    newUser[property] = event.target.value;
    setUser(newUser);
  }
  console.log('Response: ', response);
  function sendForm() {
    let data = {
      form: ecomerce_form_uuid,
      fields: user,
      product: product.uuid,
      type: 'lead'
    }
    data.fields.producto = product.name;
    http({data});
  }
  return (
      <div>
        <div ref={detailRef} className="relative">
          {product.discount_price && <Discount product={product} />}
          <img className="min-w-full mb-5" src={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${product.image}`} alt={product.name}/>
          <h1 className="text-2xl font-bold">{product.name}</h1>
          <p className="mb-3">{product.detail}</p>
          {product.file && <a download
                              className="mb-3 inline-block"
                              href={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${product.file}`} rel="noopener noreferrer" target='_blank'>{ action_type === 'buy' ? <div><span className="mr-2">Descargar detalle del producto</span> <FontAwesomeIcon icon={faDownload} color={colors.primary} /></div> : <div><FontAwesomeIcon icon={faFile} color={colors.primary} /> <span className="ml-2">Ficha Técnica</span></div>}</a>}
          <ProductPrice className="text-2xl" product={product} />
          {action_type === 'buy' ? <CartControls product={product} className="mt-4" /> :
          <div className="mt-4">
            <button className="h-12 text-white text-center py-2 rounded w-full px-3 sm:px-2" style={{backgroundColor: colors.secondary}} onClick={() => { detailRef.current.style.display = 'none'; formRef.current.style.display = 'inline';}}>
              {action_message}
            </button>
          </div>}
        </div>
        <div ref={formRef} style={{display: 'none'}}>
          <div className="w-full mb-2 p-2" style={{backgroundColor: 'white', borderRadius: '10px'}}>
            <p className="sm:mt-3 sm:mb-1 text-lg row-auto" >
              <img  src={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${product.image}`} alt={product.name} style={{maxWidth: width > 750 ? '45%': '100%', paddingRight: width > 750 ? '5%' : '0%' }}/>
              <span style={{maxWidth: width > 750 ? '50%': '100%', position: width > 750 ? 'absolute' : 'relative', top: width > 750 ? '55px' : 'auto', left: width > 750 ? '46%' : 'auto'}}>
                <h1 className="text-2xl font-bold" >{product.name}</h1>
                <ProductPrice className="text-2xl font-bold" product={product}/>
              </span>
            </p>
            <div className="row-auto my-2" style={{backgroundColor: '#EDEDED', borderRadius: '10px'}}>
            <form className="sm:py-2" >
                {fields.map((field, index) =>
                  <input type="text"
                    className={index === (fields.length - 1) ? 'mb-5 h-12 pl-2': index === 0 ? 'mt-5 mb-3 h-12 pl-2' : 'mb-3 h-12 pl-2'}
                    placeholder={Boolean(field.required) ? (String(field.label) +' (requerido)'): String(field.label) }
                    required={Boolean(field.required)}
                    style={{borderRadius: '5px', minWidth: width > 750 ? '80%': '95%' ,maxWidth: width > 750 ? '80%': '95%', marginLeft: width > 750 ? '10%' : '2.5%', marginRight: width > 750 ? '10%' : '2.5%'}}
                    onChange={(event) => onInputChange(event, field.label)}
                  />)}
                <input className="h-12 text-white text-center py-2 rounded px-3 sm:px-2 mx-auto my-3" type="submit" value={action_message} style={{backgroundColor: colors.secondary, minWidth: width > 750 ? '80%': '95%' ,maxWidth: width > 750 ? '80%': '95%', marginLeft: width > 750 ? '10%' : '2.5%', marginRight: width > 750 ? '10%' : '2.5%' }} onClick={() => { sendForm()}}/>
            </form>
            </div>
          </div>
        </div>
      </div>
  );
}

function ProductDetail(props: {company: Company, colors: Colors}) {
  const {company, colors} = props;
  const {url} = useRouteMatch();
  const {product} = useParams();
  const [{data, loading, error}, refetch] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/product/${product}`,
    headers: {
      Company: company.uuid,
    }
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  function closeModal() {
    window.location.href = url.substring(0, url.lastIndexOf('/'));
  }

  return (
      <Modal
          isOpen={true}
          onRequestClose={closeModal}
          style={{content: {maxWidth: 500}}}
      >
        <button onClick={() => closeModal()} className="modal-close rounded-full bg-gray-500 text-white p-1">
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        {loading && <div className="px-16 pt-5"><Loader /></div>}
        {error && <Error message={error.message} />}
        {data && <ProductBody product={data.success} colors={colors} action_type={company.action_type} action_message={company.cart_action_message} contact_fields={company.contact_fields} ecomerce_form_uuid={company.ecommerce_form.uuid} company_uuid={company.uuid}/>}
      </Modal>
  );
}

export default connect(mapStateToProps)(ProductDetail);
