import * as React from 'react';
import {CartItem} from "./CartItem";
import Price from "../components/Price";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {connect} from "react-redux";
import {removeFromCart, updateProductCount, updateProductNote} from "../actions";
import ProductPrice from "../products/ProductPrice";
import ChangeCartQuantity from "../products/ChangeCartQuantity";
import {mapStateToProps} from "../reducers";
import Company from "../company/Company";
import {useState} from "react";

function CartListItem(props: { item: CartItem, columnWidths: string[], index: number, dispatch: Function, company: Company }) {
  const {item, columnWidths, dispatch, index} = props;
  const total = item.discount_price ? item.discount_price * item.quantity : item.price * item.quantity;
  const [quantity, setQuantity] = useState(item.quantity);
  const [note, setNote] = useState(item.note);

  const decrementCart = () => {
    const q = quantity - 1;

    // Update product quantity
    if (quantity > 0 ) {
      dispatch(updateProductCount(index, q));
    }

    setQuantity(q);
  }

  const incrementCart = () => {
    const q = item.quantity + 1;
    dispatch(updateProductCount(index, q));
    setQuantity(q);
  }

  const onNoteInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    dispatch(updateProductNote(index, value));
    setNote(value);
  }

  function remove() {
    dispatch(removeFromCart(index));
  }

  return (
      <div>
        <div className={`mb-3 p-3 ${item.quantity === 0 ? 'opacity-50': ''}`}>
          <div className="flex flex-wrap justify-between md:justify-center content-center">
            <div className={`${columnWidths[0]} flex items-center mb-3 sm:mb-0`}>
              <button className="w-10 h-10 mr-3 flex-shrink-0 bg-red-500 rounded-full shadow-md"
                      onClick={() => remove()}><FontAwesomeIcon icon={faTrash} className="text-white" /></button>
              <img className="w-16 mr-3"
                   src={`${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/storage/${item.image ? item.image : item.combo_image}`}
                   alt={item.name}/>
               <div className="flex-grow">
                 <div>{item.name}</div>
                 <div className="block sm:hidden"><Price className="font-bold text-lg" price={total} /></div>
               </div>
              <div className="flex sm:hidden">
                <ChangeCartQuantity quantity={item.quantity}
                                    direction="vertical"
                                    onClickPlus={incrementCart}
                                    onClickMinus={decrementCart}
                                    index={index}
                                    setQuantity={setQuantity}

                />
              </div>
            </div>
            <div className={`${columnWidths[1]} flex items-center`}><ProductPrice product={item} /></div>
            <div className={`${columnWidths[2]} flex items-center`}>
              <div>
                <ChangeCartQuantity quantity={item.quantity}
                                    direction="horizontal"
                                    onClickPlus={incrementCart}
                                    onClickMinus={decrementCart}
                                    index={index}
                                    setQuantity={setQuantity}

                />
              </div>
            </div>
            <div className={`${columnWidths[3]} items-center justify-end flex-shrink-1`}><Price price={total} /></div>
          </div>
          <div className="mt-2">
            <input type="text"
                   className="border p-2 w-full rounded"
                   placeholder="Escribe un mensaje"
                   value={note}
                   onChange={(event) => onNoteInputChange(event)}/>
          </div>
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(CartListItem);
