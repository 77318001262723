import * as React from 'react';
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
// eslint-disable-next-line
import {emptyCart, setCartLocation, setUser} from "../actions";
import User from "./User";
import {Link} from "react-router-dom";
import Company from "../company/Company";
import {
  faArrowLeft,
  faArrowRight, faCheckCircle, faTimes, faCopy
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Input from "../components/Input";
import CartHeader from "./CartHeader";
import Fab from "../components/Fab";
import {Cart} from "./Cart";
import {findCompanyCart} from "./cart.utils";
import useAxios from "axios-hooks";
// eslint-disable-next-line
//import Select from"react-select";
import Loader from "../components/Loader";
import Error from "../components/Error";
import {useEffect, useState} from "react";
import Modal from 'react-modal';
import useWindowDimensions from '../components/useWindowDimensions';
import order from '../assets/order.gif'

export type Location = {
  uuid: string;
  name: string;
}

function UserData(props: { carts: Cart[], dispatch: Function, company: Company, colors: Colors }) {
  const {carts, dispatch, company, colors} = props;
  const cart = findCompanyCart(company, carts);
  // eslint-disable-next-line
  const [locations, setLocations] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [user, setUser] = useState(new User());
  const width = useWindowDimensions().width;
  //const height = useWindowDimensions().height;
  const [{data}] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/locations`,
    headers: {
      Company: company.uuid,
    }
  });

  const [response, http] = useAxios({
    url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/leads`,
    method: 'POST',
    headers: {
      Company: company.uuid,
    }
  }, {manual: true});

  let copyRef:any = React.createRef();
  let CopiedRef:any = React.createRef();
  const errorMessage = response.error?.response?.data.error || response.error?.message;

  useEffect(() => {
    if (data && data.success) {
      setLocations(data.success.map((location: Location) => {
        return {
          label: location.name,
          value: location.uuid,
        }
      }));
    }
  }, [data]);

  useEffect(() => {
    if (response.data && response.data.success) {
      setOrderNumber(response.data.success.order.uuid);
      setShowSuccessModal(true);
      dispatch(emptyCart());
    }
  }, [response.data, dispatch]);

  const onInputChange = (event: React.FormEvent<HTMLInputElement>, property: string) => {
    const newUser = {...user};
    // @ts-ignore
    newUser[property] = event.target.value;
    setUser(newUser);
  }

// eslint-disable-next-line
  function handleLocationChange(location: any) {
    dispatch(setCartLocation(location));
  }

  function sendForm() {
    setShowConfirmModal(false);

    const products = cart.items.map(item => {
      return {
        product: item.uuid,
        quantity: item.quantity,
        note: item.note,
      }
    });
    const data = {
      form: company.ecommerce_form.uuid,
      fields: user,
      order: {
        products,
        paymentMethod: cart.paymentMethod.value,
        location: cart.location?.value,
      },
      type: 'buy'
    }

    http({data});
  }

  return (
      <div className="flex flex-col w-full h-full">
        {!cart.items.length && <Error message="No tienes productos en el carrito"/>}
        {response.loading && <div className="overlay z-30"><Loader/></div>}
        <div className="flex items-center px-3 py-2 text-white top-0 left-0 right-0 z-10 sm:mb-8"
             style={{backgroundColor: colors.primary}}>
          <Link to={`orden`}><FontAwesomeIcon icon={faArrowLeft}/> Regresar</Link>
        </div>
        <div className="flex justify-center content-center items-center h-full p-3 pb-16 sm:pb-24">
          <div className="max-w-2xl w-full">
            <CartHeader/>
            {cart.paymentMethod.value !== 'cash' && cart.paymentMethod.value !== 'credit_card' && <div className="border p-3 bg-green-200 text-center text-black text-lg rounded row" role="alert">
            <span className="inline" style={{fontSize: width > 750 ? '0.95rem': '0.8rem'}}>Se enviará el número de cuenta al finalizar el proceso de compra</span>
            {/*<span className="relative inline float-right px-4">
              <svg className="fill-current h-6 w-6 text-black text-sm" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>*/}
          </div>}
          {cart.paymentMethod.value !== 'cash' && cart.paymentMethod.value !== 'transfer' && <div className="border p-3 bg-green-200 text-center text-black text-lg rounded row" role="alert">
            <span className="inline" style={{fontSize: width > 750 ? '0.95rem': '0.8rem'}}>Se enviará el enlace de pago al finalizar el proceso de compra</span>
            {/*<span className="relative inline float-right px-4">
              <svg className="fill-current h-6 w-6 text-black text-sm" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>*/}
          </div>}
            <h2 className="text-center my-6">Registra tu compra como:</h2>
            <div className="flex flex-col justify-center items-center">
              <div className="w-full sm:w-1/2">
                {company.contact_fields.map(field => <Input
                    label={`${field.label}${field.required ? ' (requerido)' : ''}`}>
                  <input type="text"
                         className="border p-2 w-full rounded border-gray-500"
                         value={user[field.label]}
                         required={field.required}
                         onChange={(event) => onInputChange(event, field.label)}/>
                </Input>)}
                {errorMessage && <Error message={errorMessage}/>}
              </div>
              {company.legal_message &&
              <h2 className="text-center my-6">Términos y condiciones: <br/>{company.legal_message}</h2>}
            </div>
          </div>
        </div>

        <Modal
            isOpen={showConfirmModal}
            onRequestClose={() => setShowConfirmModal(false)}
            style={{content: {maxWidth: 500}}}
        >
          <div>
            <button onClick={() => setShowConfirmModal(false)} className="modal-close">
              <FontAwesomeIcon icon={faTimes} size="2x"/>
            </button>
            <h2 className="text-lg text-black text-left mb-5">Confirmar pedido</h2>
            <p>¿Quieres confirmar y finalizar este pedido?</p>
            <div className="flex mt-5">
              <button className="mx-3" onClick={() => setShowConfirmModal(false)}>Regresar</button>
              <button className="mx-3 p-3 text-white rounded w-full"
                      style={{backgroundColor: colors.secondary}}
                      onClick={() => sendForm()}>Confirmar
              </button>
            </div>
          </div>
        </Modal>

        <Modal
            isOpen={showSuccessModal}
            onRequestClose={() => setShowSuccessModal(false)}
            style={{content: {maxWidth: width > 750 ? 650 : '100%', minWidth: width > 750 ? 650 : '100%', padding: 'none'}}}
        >
          <div className="text-white text-center w-full">
            <div className="w-full mb-2 p-2" style={{backgroundColor: colors.primary}}>
              <Link to={`/${company.slug}/productos`} className="modal-close">
                <FontAwesomeIcon icon={faTimes} size="2x"/>
              </Link>
              <p className="mt-3 mb-1 text-lg">
                {width > 750 ?  <FontAwesomeIcon icon={faCheckCircle} className="mr-3 mb-5" size="2x" style={{verticalAlign: 'middle', marginTop: '10px'}}/>  : ''}
                <span> N&deg; de pedido: <strong>{orderNumber}</strong></span>
                <button className="ml-3 mb-2" onClick={() => { copyRef.current.style.display = 'none'; CopiedRef.current.style.display = 'inline'; navigator.clipboard.writeText(orderNumber); }}>
                  <div ref={copyRef} style={{display: 'inline', transition: 'display 1s'}}><FontAwesomeIcon icon={faCopy} size="1x"/>  Copiar</div>
                  <div ref={CopiedRef} style={{display: 'none', transition: 'display 1s'}}><FontAwesomeIcon icon={faCheckCircle} size="1x"/> Copiado</div>
                </button>
              </p>
              <p className=" text-lg" style={{fontSize: '0.75rem'}}>
                No te olvides de copiar el número de tu pedido para que puedas revisar su estado
              </p>
            </div>
            <div className="mb-5">
              <div className="h-full sm:h-2/6 m-2 ml-0"
                  style={{borderRadius: `1rem`}}>
                <img src={order} alt="pedido Entregado" style={{maxWidth: width > 750 ? '50%' : '100%', marginLeft: width > 750 ? '25%': '0%' }}/>
              </div>
              {/*<FontAwesomeIcon icon={faCheckCircle} size="8x"/>*/}
            </div>
            {/*<p>Un asesor se contactará en breve</p>*/}
          </div>
        </Modal>

        <Fab>
          <button className="fab-button uppercase"
                  disabled={!cart.items.length}
                  style={{backgroundColor: colors.accent}}
                  onClick={() => setShowConfirmModal(true)}>
            Realizar pedido <FontAwesomeIcon className="ml-2" icon={faArrowRight}/>
          </button>
        </Fab>
      </div>
  );
}

export default connect(mapStateToProps)(UserData);
