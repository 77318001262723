export class FormField {
  label!: string;
  required!: boolean;
}
export class Banner {
  name!: string;
  detail!: string;
  image!: string;

}
export class Day {
  key!: string;
  label!: string;
  start!: string;
  end!: string;
  enabled!: boolean;

}

export default class Company {
  uuid!: string;
  name!: string;
  logo!: string;
  favicon!: string;
  address!: string;
  email!: string;
  mobile!: string;
  title!: string;
  text!: string;
  phone!: string;
  schedule!: string;
  cart_min_value!: number;
  payment_methods!: string[];
  days!: Day[];
  public_banners!: Banner[];
  ecommerce_form!: {uuid: string};
  slug!: string;
  cart_action_message!: string;
  meta_description!: string;
  meta_title!: string;
  show_random_products!: boolean;
  messages!: string[];
  legal_message!: string;
  contact_fields!: FormField[];
  action_type!: string;
  action_message!: string;
  colors: any;
}
