import * as React from 'react';
import {NavLink, useRouteMatch} from "react-router-dom";
import {ALL_ROUTE} from "../contants";
import Category from "./Category";
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import useWindowDimensions from '../components/useWindowDimensions';

function Categories(props: { categories: Category[], colors: Colors}) {
  const {categories, colors} = props;
  const {url} = useRouteMatch();
  const width = useWindowDimensions().width;
  //const height = useWindowDimensions().height;

  return (
      <div className="overflow-x-auto sm:justify-center mb-2 sm:mb-8 pb-4 md:pb-0 sticky top-0 bg-gray-200 z-10 " style={{marginTop: width < 750 ? '25px' : 'auto'}}>
        <div className="flex sm:justify-center px-3" style={{minWidth: 'fit-content'}}>
          <NavLink exact to={`${url}/${ALL_ROUTE}`}
                   className="m-1 block py-2 px-5 font-regular "
                   activeClassName="font-bold " style={{borderRadius:'7.5px', fontSize: width > 750 ? '1.15rem' : '0.95rem', backgroundColor: window.location.href.includes('todos') ? colors.secondary : 'transparent', color: window.location.href.includes('todos') ? 'white' : '#787878'}}>Todos</NavLink>
          {categories.map((category, index) => (
              <NavLink key={index} to={`${url}/${category.slug}`}
                       className="m-1 no-break block py-2 px-5 font-regular"
                       activeClassName="font-bold" style={{borderRadius:'7.5px', fontSize: width > 750 ? '1.15rem' : '0.95rem', backgroundColor: window.location.href.includes(category.slug) ? colors.secondary : 'transparent', color: window.location.href.includes(category.slug) ? 'white' : '#787878'}}>{category.name}</NavLink>
          ))}
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(Categories);
