import * as React from 'react';
import {Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import ProductDetail from "./ProductDetail";
import Loader from "../components/Loader";
import Error from "../components/Error";
import Product from "./Product";
import {connect} from "react-redux";
// eslint-disable-next-line
import {addProducts, setPaymentMethod, setSortingMethod} from "../actions";
import {initialSortingMethods, mapStateToProps, Sort} from "../reducers";
import ProductItem from "./ProductItem";
import {ALL_ROUTE} from "../contants";
import Company from "../company/Company";
import useAxios from 'axios-hooks'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import Select from "react-select";
import Adds from '../components/Adds';

function ProductList(props: {dispatch: (products: any) => void, products: Product[], sorting: Sort, company: Company}) {
  const {company, dispatch, products, sorting } = props;
  const {url} = useRouteMatch();
  const {category} = useParams();
  const [filteredProducts, setFilteredProducts] = useState(products);
  for (let i = 0; i < filteredProducts.length; i++) {
    if (filteredProducts[i].is_combo) {
      filteredProducts.splice(i, 1);
    }
  }
  const [combos_response] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/combos`,
      headers: {
        Company: company.uuid,
      }
  });
  const showCombos = combos_response.data?.success && combos_response.data?.success.length > 0;
  // eslint-disable-next-line
  const [sortingMethods, setSortingMethods] = useState(initialSortingMethods);

  const sortQuery = `sort[by]=${sorting.method}&sort[direction]=${sorting.direction}`;
  const filterQuery = category === ALL_ROUTE ? '' : `&category[slug]=${category}`;
  const [{data, loading, error}, refetch] = useAxios(
      {
        url: `${process.env.REACT_APP_HOST || 'https://leadbox.ec'}/api/v1/products?${sortQuery}${filterQuery}`,
        headers: {
          Company: company.uuid,
        }
      }
  );

  useEffect(() => {
    refetch();
  }, [sorting, refetch]);

  // Store to global state
  useEffect(() => {
    if (data) {
      dispatch(addProducts(data.success));
      setFilteredProducts(data.success);
    }
  }, [data, dispatch]);

  function handleOrderChange(method: any) {
    dispatch(setSortingMethod(method));
  }

  function search(event: React.FormEvent<HTMLInputElement>) {
    const query = event.currentTarget.value.toLowerCase();
    const filtered = products.filter(product => product.name.toLowerCase().includes(query) || product.detail.toLowerCase().includes(query));
    setFilteredProducts(filtered);
  }

  if (loading || !products) return <Loader/>
  if (error) return <Error message={error.message}/>

  return (
      <div>
        <div className="flex flex-col sm:flex-row justify-between items-center mb-3">
          <div className="uppercase text-lg mb-3 sm:mb-0 hidden sm:block">
            <strong>{filteredProducts.length}</strong> <span className="text-sm">Productos</span>
          </div>
          <div className="sm:flex grid grid-cols-2 gap-4 w-full sm:w-auto">
            <label className="inline-flex w-full sm:w-auto sm:rounded-full items-center bg-white overflow-hidden border-gray-500 border">
              <div className="w-10 flex justify-center items-center px-2">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <input type="text"
                     className="sm:p-2 outline-none"
                     onChange={search}
                     placeholder="Buscar..."/>
            </label>
            <Select
                isSearchable={false}
                className="border-gray-500 w-full sm:hidden lg:text-base text-sm"
                value={sorting}
                onChange={handleOrderChange}
                options={sortingMethods}
            />
          </div>
        </div>
        { showCombos ? filteredProducts.length >= 6  ?
        <div>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredProducts.map((product: Product, idx) => idx <= 5 ? <ProductItem key={product.uuid} product={product} action_type={company.action_type} action_message={company.cart_action_message} color={company.colors.secondary} contact_fields={company.contact_fields}/>: '')}
          </div>
          <Adds actionMessage={company.cart_action_message} color={company.colors.secondary} adds={combos_response.data?.success} action_type={company.action_type}/>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 my-3">
            {filteredProducts.map((product: Product, idx) => idx > 5 ? <ProductItem key={product.uuid} product={product} action_type={company.action_type} action_message={company.cart_action_message} color={company.colors.secondary} contact_fields={company.contact_fields}/>: '')}
          </div>
        </div>
        :
        <div>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredProducts.map((product: Product, idx) => idx <= 5 ? <ProductItem key={product.uuid} product={product} action_type={company.action_type} action_message={company.cart_action_message} color={company.colors.secondary} contact_fields={company.contact_fields}/>: '')}
          </div>
          <Adds actionMessage={company.cart_action_message} color={company.colors.secondary} adds={combos_response.data?.success} action_type={company.action_type}/>
        </div>
        :
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredProducts.map((product: Product) => <ProductItem key={product.uuid} product={product} action_type={company.action_type} action_message={company.cart_action_message} color={company.colors.secondary} contact_fields={company.contact_fields}/>)}
          </div>
        }
        {filteredProducts.length === 0 && <Error message="No existen productos" />}
        <Switch>
          <Route path={`${url}/:product`}>
            <ProductDetail />
          </Route>
        </Switch>
      </div>
  );
}

export default connect(mapStateToProps)(ProductList);
