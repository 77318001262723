import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Main from "./main/Main";
import Home from "./home/Home";
import {cartApp} from "./reducers";
import {Provider} from "react-redux";
import {createStore} from "redux";
import {loadState, saveState} from "./localstorage";
import ReactTooltip from "react-tooltip";
import {renderToString} from "react-dom/server";
import {Helmet} from "react-helmet";
import LeadShop from "./leadshop/LeadShop";
import ReactPixel from 'react-facebook-pixel';
import Modal from 'react-modal';

const initialState = loadState();
const store = createStore(cartApp, initialState);
// @ts-ignore
//const store = createStore(cartApp, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// TODO: use redux dev tools and initialState
/*const store = createStore(
    cartApp,
    // @ts-ignore
    {},
    compose(
        initialState,
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);*/

store.subscribe(() => {
  saveState(store.getState());
});

ReactPixel.init('2312278085742591');
ReactPixel.pageView();
// @ts-ignore
ReactPixel.fbq('track', 'PageView');

Modal.setAppElement('#root');
if (Modal.defaultStyles.overlay) {
  Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';
  Modal.defaultStyles.overlay.zIndex = 100;
  Modal.defaultStyles.overlay.display = 'flex';
  Modal.defaultStyles.overlay.overflow = 'auto';
}
if (Modal.defaultStyles.content) {
  Modal.defaultStyles.content.padding = '1.25rem';
  Modal.defaultStyles.content.position = 'relative';
  Modal.defaultStyles.content.minHeight = '8rem';
  Modal.defaultStyles.content.borderRadius = '0.375rem';
  Modal.defaultStyles.content.boxShadow = '0 25px 50px -12px rgba(0, 0, 0, 0.25)';
  Modal.defaultStyles.content.border = 'none';
  Modal.defaultStyles.content.display = 'flex';
  Modal.defaultStyles.content.justifyContent = 'center';
  Modal.defaultStyles.content.alignItems = 'center';
  Modal.defaultStyles.content.margin = 'auto';
  Modal.defaultStyles.content.top = 0;
  Modal.defaultStyles.content.left = 0;
  Modal.defaultStyles.content.bottom = 0;
  Modal.defaultStyles.content.right = 0;
}

function App() {
  return (
      <Provider store={store}>
        <Helmet>
          <link rel="icon" href="/favicon.png" />
          <title>LeadShop</title>
        </Helmet>
        <Router>
          <Switch>
            <Route path="/leadshop">
              <LeadShop/>
            </Route>
            <Route path="/:slug">
              <Main/>
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </Router>
        <ReactTooltip />
      </Provider>
  );
}

export default App;

export const appString = () => renderToString(<App />);
