import * as React from 'react';
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import Price from "../components/Price";
import Select from "react-select";
import {PaymentMethods} from "../company/PaymentMethods";
import Company from "../company/Company";
import {calculateCartTotal, findCompanyCart} from "./cart.utils";
import {Cart} from "./Cart";
import {setPaymentMethod} from "../actions";
import {useEffect, useState} from "react";
import useWindowDimensions from '../components/useWindowDimensions';

function CartHeader(props: {carts: Cart[], colors: Colors, company: Company, dispatch: Function}) {
  const {carts, colors, company, dispatch} = props;
  const cart = findCompanyCart(company, carts);
  const [total, setTotal] = useState(0);
  const options = PaymentMethods.filter(method => company.payment_methods.find(x => x === method.value));
  const width = useWindowDimensions().width;

  useEffect(() => {
    const t = calculateCartTotal(cart);
    setTotal(t);
  }, [cart]);

  function handlePaymentChange(method: any) {
    dispatch(setPaymentMethod(method));
  }

  return (
      <div className="flex flex-col sm:flex-row justify-center items-center sm:items-baseline sm:justify-between shadow border rounded mb-3 p-3 px-6 bg-white">
        <div className="sm:flex items-end hidden">
          <div className="w-6 h-6 rounded-full text-white flex items-center justify-center mr-2" style={{backgroundColor: colors.primary}}>
            {cart.items.filter(x => x.quantity > 0).length}
          </div>
          Items en el pedido
        </div>
        <div className="flex items-baseline">
          <Price price={total} className="text-5xl font-bold mr-2" />
          <div className="text-lg">Inc. IVA.</div>
        </div>
        <div className="flex w-full sm:w-3/12 items-end mb-3">
          <Select
              isSearchable={false}
              className="border-gray-500 w-full"
              value={cart.paymentMethod}
              onChange={handlePaymentChange}
              options={options}
              style={{fontSize: width > 750 ? '0.8rem': '0.7rem', minWidth: width > 750 ? '160px' : ''}}
          />
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(CartHeader);
