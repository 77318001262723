import * as React from 'react';
import {connect} from "react-redux";
import {Colors, mapStateToProps} from "../reducers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import Price from "../components/Price";
import {calculateCartTotal, findCompanyCart} from "./cart.utils";
import Company from "../company/Company";
import Fab from "../components/Fab";
import {Cart} from "./Cart";
import {useEffect, useState} from "react";
import useWindowDimensions from '../components/useWindowDimensions';

function MiniCart(props: {carts: Cart[], colors: Colors, company: Company}) {
  const {carts, colors, company} = props;
  const cart = findCompanyCart(company, carts);
  const [total, setTotal] = useState(0);
  const width = useWindowDimensions().width;
  useEffect(() => {
    const t = calculateCartTotal(cart);
    setTotal(t);
  }, [cart]);

  return (
      <Fab>
        <NavLink className="fab-button sm:w-auto sm:rounded-lg" to={`/${company.slug}/carrito`}
                 activeClassName="hidden"
                 style={{backgroundColor: colors.accent}}>
          <div className="flex justify-center content-center items-center">
            <div className="flex items-baseline">
              <div className="text-xl md:text-2xl mr-4" style={{fontSize: width > 750 ? '1.25rem': '1.15rem'}}>{cart.items.filter(x => x.quantity > 0).length} items</div>
              <Price className="font-bold text-2xl md:text-4xl" price={total}/>
            </div>
            <div className="ml-10">
              <FontAwesomeIcon icon={faArrowRight} />
              <FontAwesomeIcon className="ml-2" icon={faShoppingCart} />
            </div>
          </div>
        </NavLink>
      </Fab>
  );
}

export default connect(mapStateToProps)(MiniCart);
