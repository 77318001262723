import * as React from 'react';
import Product from "./Product";
import Price from "../components/Price";

function ProductPrice(props: {product: Product, className?: string}) {
  const {product, className} = props;

  return (
      <div className={className}>
        {product.discount_price && <Price className="font-bold mr-3" price={product.discount_price} />}
        <Price className={`${product.discount_price ? 'line-through text-sm' : 'bold text-lg'}`} price={product.price} />
      </div>
  );
}

export default ProductPrice;
